<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <Header></Header>
    <!--  END NAVBAR  -->

    <div class="overlay"></div>

    <!--  BEGIN MAIN CONTAINER  -->
    <main class="main-container" id="container"
          :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']">

      <!--  BEGIN OVERLAY  -->
      <div class="overlay" :class="{ show: !$store.state.is_show_sidebar }"
           @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"></div>
      <!-- END OVERLAY -->

      <!--  BEGIN SIDEBAR  -->
      <Sidebar></Sidebar>
      <!--  END SIDEBAR  -->

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <!-- BEGIN LOADER -->
        <loader/>
        <!--  END LOADER -->
        <transition name="fade" mode="out-in">
          <router-view>

          </router-view>
        </transition>

        <!-- BEGIN FOOTER -->
        <Footer></Footer>
        <!-- END FOOTER -->
      </div>
      <!--  END CONTENT AREA  -->

      <!-- BEGIN APP SETTING LAUNCHER -->
      <!--            <app-settings />-->
      <!-- END APP SETTING LAUNCHER -->
    </main>
  </div>
</template>
<script>
import Header from '../components/layout/header.vue';
import Sidebar from '../components/layout/sidebar.vue';
import Footer from '../components/layout/footer.vue';
import Loader from "../components/loader";

export default {
  components: {
    Loader,
    Header,
    Sidebar,
    Footer,
  },
  data() {
    return {};
  }

};
</script>
<style>
@keyframes fadeInUpUP {
  from {
    transform: translate3d(0, 30px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

.fade-leave-to {
  opacity: 0;
  transition: opacity .1s;
}

.fade-enter {
  opacity: 0;
  transform: translate3d(0, 30px, 0);
}

.fade-enter-to {
  opacity: 0;
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-name: fadeInUpUP;
}
</style>
