<template>
   <div class="layout-px-spacing" v-if="
      this.$can('admin') ||
      this.$can('office') ||
      this.$can('technik-manager') ||
      this.$can('obchodnik-manager') ||
      this.$can('rola-pobocka') ||
      this.$can('skladnik') ||
      this.$can('kurier') ||
      this.$can('infolinka') ||
      this.$can('technik')
   ">

      <div class="row layout-spacing" v-if="!loadingStatus && order">
         <!-- Content -->


         <div class="col-xl-4 layout-top-spacing">
            <div class="skills layout-spacing">
               <div class="panel">
                  <div class="panel-body">
                     <div class="d-flex justify-content-between">

                        <h3 class="mb-3">{{ $t('info') }}</h3>
                        <div>

                           <!--                            <router-link :to="`/objednavky/${order.id}/edit`" class="mt-2 edit-profile">-->

                           <!--                              Pridať príjemku-->
                           <!--                            </router-link>-->
                           <router-link :to="`/objednavky/${order.id}/edit`" class="mt-2 mr-2 edit-profile"
                              v-if="order.status === 'CREATED'">

                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" class="feather feather-edit-2 edit">
                                 <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                              </svg>
                           </router-link>
                           <a href="javascript:;" @click="remove()" v-b-tooltip.hover :title="$t('delete')"
                              v-if="$can('admin')">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" class="feather feather-trash-2">
                                 <polyline points="3 6 5 6 21 6"></polyline>
                                 <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                 </path>
                                 <line x1="10" y1="11" x2="10" y2="17"></line>
                                 <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                           </a>

                        </div>
                     </div>
                     <div class="item-box">
                        <div class="item">
                           <p>{{ $t('name') }}:</p>
                           <h6>{{ order.name }}</h6>
                        </div>
                        <div class="item">
                           <p>{{ $t('identifier') }}:</p>
                           <h6>{{ order.identifier }}</h6>
                        </div>
                        <!--                <div class="item" v-if="this.$can('admin')">
                                          <p class="mr-4"><strong>Stav:</strong></p>
                                          <b-select class="form-select form-select-sm w-100" v-model="order.status" v-on:change="changedOrderStatus">
                                            <option value="CREATED">Vytvorená</option>
                                            <option value="IN_PROGRESS">Prebieha</option>
                                            <option value="COMPLETED">Dokončená</option>
                                          </b-select>
                                        </div>-->
                        <div class="item">
                           <p>{{ $t('status') }}:</p>
                           <h6 class="badge inv-status" :class="getClass(order.status_value)">
                              {{ getTranslation(order.status_value) }}
                           </h6>
                        </div>
                        <div class="item">
                           <p>{{ $t('customer') }}:</p>
                           <h6>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" class="feather feather-user placeholder">
                                 <path data-v-17470ab0="" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                 <circle data-v-17470ab0="" cx="12" cy="7" r="4"></circle>
                              </svg>
                              {{ order.from_user.name }}
                           </h6>
                        </div>
                        <div class="item">
                           <p>{{ $t('supplier') }}:</p>
                           <h6>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" class="feather feather-user placeholder">
                                 <path data-v-17470ab0="" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                 <circle data-v-17470ab0="" cx="12" cy="7" r="4"></circle>
                              </svg>
                              {{ order.to_user.name }}
                           </h6>
                        </div>
                        <div class="item">
                           <p>{{ $t('created_by') }}:</p>
                           <h6>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" class="feather feather-user placeholder">
                                 <path data-v-17470ab0="" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                 <circle data-v-17470ab0="" cx="12" cy="7" r="4"></circle>
                              </svg>
                              {{ order.created_by_name }}
                           </h6>
                        </div>
                        <div class="item">
                           <p>{{ $t('note') }}:</p>
                           <h6>{{ order.note }}</h6>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class=" skills layout-spacing custom-table">
               <div class="panel">
                  <div class="panel-body">
                     <div class="d-flex justify-content-between">

                        <h3 class="mb-3">{{ $t('order_items') }}</h3>

                     </div>
                     <div class="table-responsive">
                        <table class="table table-hover w-100">
                           <thead>
                              <tr>
                                 <th>{{ $t('name') }}</th>
                                 <th>{{ $t('quantity') }}</th>
                                 <th>{{ $t('note') }}</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-if="order.items.length === 0" key="empty">
                                 <td colspan="6" class="text-center">{{ $t('no_items') }}</td>
                              </tr>
                              <tr v-for="item in order.items" :key="item.id">
                                 <td>
                                    <div class="d-flex align-items-center"
                                       style="max-width: 6rem; white-space: initial;">
                                       <!--                                       <svg style="flex: none; width: 0.9rem; margin-right: 0.3rem;" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">-->
                                       <!--                                          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>-->
                                       <!--                                          <circle cx="12" cy="12" r="3"></circle>-->
                                       <!--                                       </svg>-->
                                       <svg v-if="item.unit_id === ''"
                                          style="flex: none; width: 0.9rem; margin-right: 0.3rem;" v-b-tooltip.hover
                                          :title="$t('stock_item')" viewBox="0 0 24 24" width="24" height="24"
                                          stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round"
                                          stroke-linejoin="round" class="css-i6dzq1">
                                          <path
                                             d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
                                          </path>
                                          <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                          <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                       </svg>
                                       <svg v-else
                                          style="flex: none; width: 0.9rem; margin-right: 0.3rem; fill: #515365;"
                                          v-b-tooltip.hover :title="$t('non-stock_item')" id="a"
                                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" stroke="currentColor"
                                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          class="css-i6dzq1">
                                          <path
                                             d="M2.75,21.93c-.18,0-.37-.05-.53-.15-.47-.29-.61-.91-.31-1.38L14.48.47c.29-.47.91-.61,1.38-.31.47.29.61.91.31,1.38L3.59,21.46c-.19.3-.51.47-.85.47Z"
                                             stroke-width="0" />
                                          <path
                                             d="M1.5,17.94l.68.39,1.07-1.69-.75-.43c-.31-.18-.5-.51-.5-.86v-7.46l4.97,2.87,1.07-1.69-5-2.89,6.46-3.69c.15-.09.33-.13.5-.13s.35.05.5.14l1.24.71,1.07-1.69-1.31-.75c-.93-.53-2.08-.54-3,0L1.5,4.74c-.35.2-.62.48-.86.79-.09.07-.17.16-.24.27-.04.07-.05.14-.07.21-.2.41-.33.86-.33,1.33v8c0,1.07.58,2.06,1.5,2.6Z"
                                             stroke-width="0" />
                                          <path
                                             d="M19.6,5.8c-.07-.11-.15-.21-.25-.28-.23-.3-.51-.58-.85-.77l-2.52-1.44-1.07,1.69,2.05,1.17-4.4,2.54-3.56,5.65v5.56l-2.58-1.47-1.07,1.69,3.15,1.8c.32.18.66.28,1.01.34.15.09.31.15.49.15s.34-.06.49-.15c.35-.06.69-.15,1.01-.34l7-4c.92-.53,1.5-1.53,1.5-2.59h0V7.34c0-.47-.13-.91-.33-1.32-.02-.07-.03-.15-.07-.22ZM18,15.34c0,.36-.19.69-.5.86l-6.5,3.72v-7.99l7-4.05v7.46Z"
                                             stroke-width="0" />
                                       </svg>
                                       <!--                                       <svg style="flex: none; width: 0.9rem; margin-right: 0.3rem;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" xml:space="preserve"><g><path d="M16,2C8.2802734,2,2,8.2802734,2,16s6.2802734,14,14,14s14-6.2802734,14-14S23.7197266,2,16,2z M4,16   C4,9.3828125,9.3833008,4,16,4c2.4736328,0,4.774353,0.7531738,6.6864014,2.0410767l-1.6737671,2.0921631L16.40625,6.0859375   c-0.2587891-0.1152344-0.5537109-0.1152344-0.8125,0l-9,4C6.2329102,10.2470703,6,10.6044922,6,11v10   c0,0.3955078,0.2329102,0.7529297,0.59375,0.9140625l2.5039673,1.112854l-1.338501,1.6730957   C5.4500122,22.5115967,4,19.4252319,4,16z M22.5375977,11l-1.9501343,0.8666382l1.0229492-1.2786865L22.5375977,11z M8,12.5390625   l6.9993286,3.1108398l-4.6099243,5.7623901L8,20.3505859V12.5390625z M15,18.8509521v4.6099854l-2.7208252-1.2089844L15,18.8509521   z M17,16.3509521l0.8699341-1.0874023L24,12.5390625v7.8115234l-7,3.1103516V16.3509521z M16.612915,13.6328735L16,13.9052734   L9.4624023,11L16,8.0947266l3.7207031,1.6534424L16.612915,13.6328735z M16,28   c-2.4736328,0-4.774353-0.7531738-6.6864014-2.0410767l1.6737671-2.0921631l4.6063843,2.0473022   C15.7231445,25.9716797,15.8618164,26,16,26s0.2768555-0.0283203,0.40625-0.0859375l9-4C25.7670898,21.7529297,26,21.3955078,26,21   V11c0-0.3955078-0.2329102-0.7529297-0.59375-0.9140625l-2.5039673-1.112854l1.338501-1.6730957   C26.5499878,9.4884033,28,12.5747681,28,16C28,22.6171875,22.6166992,28,16,28z"/></g></svg>-->
                                       <span style="line-height: 1.5;     font-weight: 600;">{{ item.name }}</span>
                                    </div>

                                 </td>
                                 <td>{{ item.quantity }}<span v-html="receivedOrderItems(item.id)"></span> ({{
                                    item.unit.name }})</td>
                                 <td>{{ item.note ?? "-" }}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>

                  </div>
               </div>
            </div>

         </div>
         <div class="col-xl-8 layout-top-spacing">
            <div class="skills layout-spacing custom-table">
               <div class="panel">
                  <div class="panel-body">
                     <div class="d-flex justify-content-between">

                        <h3 class="mb-3">{{ $t('transfers') }}</h3>
                        <div>
                           <router-link
                              v-if="order.status !== 'COMPLETED' && order.movements.length > 0 && order.movements[0].type == 'OUT' && user.pouzivatel.id === order.movements[0].to_user.id"
                              :to="`/objednavky/${order.id}/nova_prijemka`" class="mt-2 mr-2 edit-profile">
                              <b-button variant="outline-success">
                                 <svg style="width: 1rem;height: 1.3rem;margin-left: -0.2rem;" viewBox="0 0 24 24"
                                    width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                    <line x1="17" y1="7" x2="7" y2="17"></line>
                                    <polyline points="17 17 7 17 7 7"></polyline>
                                 </svg>
                                 {{ $t('create_reciept') }}
                              </b-button>
                           </router-link>
                           <router-link
                              v-if="order.status !== 'COMPLETED' && (order.movements.length === 0 || order.movements[0].type == 'IN') && order.from_user.id !== user.pouzivatel.id"
                              :to="`/objednavky/${order.id}/nova_vydajka`" class="mt-2 mr-2 edit-profile">
                              <b-button variant="outline-warning">
                                 <svg style="width: 1rem;height: 1.3rem;margin-left: -0.2rem;" viewBox="0 0 24 24"
                                    width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                    <line x1="7" y1="17" x2="17" y2="7"></line>
                                    <polyline points="7 7 17 7 17 17"></polyline>
                                 </svg>
                                 {{ $t('create_issue_card') }}
                              </b-button>
                           </router-link>
                        </div>
                     </div>

                     <div class="table-responsive">
                        <table class="table table-hover w-100">
                           <thead>
                              <tr>
                                 <!--                                 <th>Id</th>-->
                                 <th>{{ $t('type') }}</th>
                                 <th>{{ $t('sender') }}</th>
                                 <th>{{ $t('recipient') }}</th>
                                 <th>{{ $t('date') }}</th>
                                 <th>{{ $t('note') }}</th>
                                 <th>{{ $t('items') }}</th>

                              </tr>
                           </thead>
                           <tbody>
                              <tr v-if="order.movements.length === 0" key="empty">
                                 <td colspan="7" class="text-center">{{ $t('no_transfers') }}</td>
                              </tr>
                              <tr v-for="movement in order.movements" :key="movement.id">
                                 <!--                                 <td>{{ movement.id }}</td>-->
                                 <td>
                                    <b-badge
                                       :class="movement.type === 'IN' ? 'outline-badge-success' : ' outline-badge-warning'"
                                       :variant="movement.type === 'IN' ? 'outline-success' : 'outline-warning'">

                                       <svg style="width: 0.9rem;height: 1rem;vertical-align: text-bottom;"
                                          v-if="movement.type === 'IN'" viewBox="0 0 24 24" width="24" height="24"
                                          stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round"
                                          stroke-linejoin="round" class="css-i6dzq1">
                                          <line x1="17" y1="7" x2="7" y2="17"></line>
                                          <polyline points="17 17 7 17 7 7"></polyline>
                                       </svg>
                                       <svg style="width: 0.9rem;height: 1rem;vertical-align: text-bottom;" v-else
                                          viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                          stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                          class="css-i6dzq1">
                                          <line x1="7" y1="17" x2="17" y2="7"></line>
                                          <polyline points="7 7 17 7 17 17"></polyline>
                                       </svg>

                                       {{ movement.type_value }}
                                    </b-badge>
                                 </td>
                                 <td>
                                    <template>{{ movement.from_user.name }}</template>
                                 </td>
                                 <td>
                                    <template>{{ movement.to_user.name }}</template>
                                 </td>
                                 <td>{{ movement.created_at }}</td>
                                 <td>{{ movement.note && movement.note !== "" ? movement.note : "-" }}</td>
                                 <td>
                                    <b-button @click="openItemsModal(movement)" variant="outline-primary" class="btn-sm"
                                       style="white-space: nowrap;">
                                       <svg
                                          style="width: 0.9rem;height: 1rem;vertical-align: text-bottom; margin-left: -0.2rem"
                                          viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                          stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                          class="css-i6dzq1">
                                          <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                                          <path
                                             d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
                                          </path>
                                          <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                          <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                       </svg>
                                       {{ $t('items') }}
                                    </b-button>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>

                  </div>
               </div>
            </div>

            <b-modal centered id="movementItemsModal" :static="true" :title="$t('transfer_items')" size="lg">
               <div v-if="actualMovement != null" class="table-responsive custom-table">
                  <table class="table table-hover">
                     <thead>
                        <tr>
                           <th>Id</th>
                           <th>{{ $t('name') }}</th>
                           <!--              <th>MJ</th>-->
                           <th>{{ $t('count') }}</th>
                           <th style="min-width: 17rem;"
                              v-if="actualMovement.items.some(item => item.attachments && item.attachments.length > 0)">
                              {{
                              $t('images') }}</th>
                           <th>{{ $t('note') }}</th>
                        </tr>
                     </thead>
                     <tbody is="transition-group">
                        <tr v-if="actualMovement.items.length === 0" key="empty">
                           <td colspan="6" class="text-center">{{ $t('no_items') }}</td>
                        </tr>
                        <tr v-for="(item, itemIndex) in actualMovement.items" :key="item.id">
                           <td tabindex="0" class="">{{ item.id }}</td>
                           <td>{{ item.name }}</td>
                           <!--              <td>{{ item.unit }}</td>-->
                           <td>{{ item.quantity }}</td>
                           <td
                              v-if="actualMovement.items.some(itemm => itemm.attachments && itemm.attachments.length > 0)">
                              <div class="image-wrapper" v-if="item.attachments">
                                 <img v-for="(image, index) in item.attachments" :key="index" :src="url + '/' + image"
                                    alt="img" @click="() => showImg(getImageIndex(itemIndex, index))">
                              </div>
                              <div v-else>-</div>
                           </td>

                           <td>{{ item.note ? item.note : "-" }}</td>

                        </tr>
                     </tbody>
                  </table>
                  <!--                          <div class="loading-row" v-if="loading">-->
                  <!--                          </div>-->
               </div>

               <template #modal-footer>
                  <b-button variant="cancel" data-dismiss="modal" @click="cancel_item">{{ $t('cancel') }}</b-button>
               </template>
            </b-modal>

            <vue-easy-lightbox :visible="visible" :imgs="imgs" :index="index" @hide="handleHide"></vue-easy-lightbox>

         </div>
      </div>
   </div>
   <div class="layout-px-spacing d-flex align-items-center justify-content-center" v-else>
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center px-3">{{ $t('not_allowed') }}</h4>
         </div>
      </div>
   </div>
</template>

<script>
import "../../../assets/sass/scrollspyNav.scss";
import "../../../assets/sass/pouzivatelia/user-profile.scss";
import "../../../assets/sass/global/table.scss";
import { mapState } from "vuex";
import { urlMixin } from "../../../mixins/url";
import { getStatusClass, getStatusTranslation } from "../statusHelper";



export default {
   metaInfo: { title: "Order detail" },
   mixins: [urlMixin],
   data() {
      return {
         actualMovement: null,
         visible: false,
         imgs: []
      };
   },
   created() {
      this.$store.dispatch("order/getOrder");
      this.$store.dispatch("auth/getUserData");
   },
   computed: {
      ...mapState({
         order: state => state.order.order,
         user: state => state.auth.userData,
         loadingStatus: state => state.order.loadingStatus
      })

   },
   methods: {
      openItemsModal(movement) {
         this.actualMovement = movement;
         this.imgs = movement.items.flatMap(item => item.attachments ? item.attachments.map(image => this.url + "/" + image) : []);
         this.$bvModal.show("movementItemsModal");
      },
      getImageIndex(itemIndex, imageIndex) {
         let totalIndex = 0;

         for (let i = 0; i < itemIndex; i++) {
            const item = this.actualMovement.items[i];
            if (item.attachments) {
               totalIndex += item.attachments.length;
            }
         }

         return totalIndex + imageIndex;
      },
      receivedOrderItems(id) {
         const itemObj = this.order.items.find(item => {
            return item.id === id;
         });
         const movementInToOrderUser = this.order.movements.filter(movement => {
            return movement.type === "IN" && movement.to_user.id === this.order.from_user.id;
         });

         let quantityItem = 0;
         movementInToOrderUser.forEach(movement => {
            const itemMovement = movement.items.find(item => {
               return item.id === id;
            });
            if (itemMovement !== undefined) {
               quantityItem += itemMovement.quantity;
            }
         });
         if (this.order.movements.length > 0) {
            if (itemObj.quantity - quantityItem === 0) {
               return "<span class=\"ml-1 badge badge-success\">OK</span>";
            } else if (itemObj.quantity - quantityItem < 0) {
               return "<span class=\"ml-1 badge badge-danger\">" + (itemObj.quantity - quantityItem) + "</span>";
            } else {
               return "<span class=\"ml-1 badge badge-warning\">" + (itemObj.quantity - quantityItem) + "</span>";
            }
         } else {
            return "<span class=\"ml-1 badge badge-warning\">" + (itemObj.quantity) + "</span>";
         }
      },
      changedOrderStatus() {
         let data = {
            status: this.order.status
         };

         let formData = new FormData();

         for (let key in data) {
            formData.append(key, data[key]);
         }

         this.$axios.post("orders/" + this.order.id + "/status", formData)
            .then((response) => {
               // this.$store.dispatch("order/getOrder");
               // this.$store.dispatch("order/getOrders");
               // this.$router.go(-1);
               this.$toast.success(response.data.message);
            }).catch(error => {
               this.$toast.error(error.response.data.message);
            });
      },
      cancel_item() {
         // this.$bvModal.hide("addItemModal");
         this.$bvModal.hide("movementItemsModal");
      },
      remove() {
         if (window.confirm(this.$t("really_want_to_remove") + " " + this.order.name + "?\n\n" + this.$t("not_change_state_order"))) {
            this.$axios.delete("orders/" + this.order.id)
               .then(() => {
                  this.$store.dispatch("order/getOrders");
                  this.$router.go(- 1);
               });
         }
      },
      showImg(index) {
         this.index = index;
         this.visible = true;
      },
      handleHide() {
         this.visible = false;
      },
      getClass(statusValue) {
         return getStatusClass(statusValue);
      },
      getTranslation(statusValue) {
         return this.$t(getStatusTranslation(statusValue));
      }
   }
};
</script>

<style scoped lang="scss">
.image-wrapper {
   display: flex;
   align-items: center;
   gap: 0.4rem;

   >img {
      width: 6rem;
      height: 4rem;
      object-fit: cover;
      border-radius: 0.5rem;
      transition: .2s;
      cursor: pointer;
      flex-wrap: wrap;

      @media all and (max-width: 500px) {
         width: 4rem;
         height: 3rem;
      }

      &:hover {
         transform: scale(1.03);
         border-radius: 0.5rem;
      }
   }
}
</style>
