<template>
   <!--  BEGIN SIDEBAR  -->
   <div class="sidebar-wrapper sidebar-theme">
      <nav ref="menu" id="sidebar">
         <div class="shadow-bottom"></div>

         <perfect-scrollbar class="list-unstyled menu-categories mt-2" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
            <router-link tag="li" to="/objednavky" class="menu" @click.native="toggleMobileMenu">
               <a class="dropdown-toggle" v-if="
                 this.$can('admin') ||
                 this.$can('office') ||
                 this.$can('technik-manager') ||
                 this.$can('obchodnik-manager') ||
                 this.$can('rola-pobocka') ||
                 this.$can('skladnik') ||
                 this.$can('kurier') ||
                 this.$can('infolinka') ||
                 this.$can('technik')
              ">
                  <div class="">
                     <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                          stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                        <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <path d="M16 10a4 4 0 0 1-8 0"></path>
                     </svg>
                     <span>{{ $t("orders") }}</span>
                  </div>
               </a>
            </router-link>
            <router-link tag="li" to="/sklad" class="menu" @click.native="toggleMobileMenu">
               <a class="dropdown-toggle" v-if="
                 this.$can('admin') ||
                 this.$can('office') ||
                 this.$can('technik-manager') ||
                 this.$can('obchodnik-manager') ||
                 this.$can('rola-pobocka') ||
                 this.$can('skladnik') ||
                 this.$can('kurier') ||
                 this.$can('infolinka') ||
                 this.$can('technik')
              ">
                  <div class="">
                     <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                          stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                        <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                        <path
                          d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                        <line x1="12" y1="22.08" x2="12" y2="12"></line>
                     </svg>
                     <span>{{ $t("store") }}</span>
                  </div>
               </a>
            </router-link>
            <router-link tag="li" to="/historia" class="menu" @click.native="toggleMobileMenu">
               <a class="dropdown-toggle" v-if="
                 this.$can('admin') ||
                 this.$can('office') ||
                 this.$can('technik-manager') ||
                 this.$can('obchodnik-manager') ||
                 this.$can('rola-pobocka') ||
                 this.$can('skladnik') ||
                 this.$can('kurier') ||
                 this.$can('infolinka') ||
                 this.$can('technik') ||
                 this.$can('obchodnik')
              ">
                  <div class="">
                     <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                          stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <line x1="16" y1="13" x2="8" y2="13"></line>
                        <line x1="16" y1="17" x2="8" y2="17"></line>
                        <polyline points="10 9 9 9 8 9"></polyline>
                     </svg>
                     <span>{{ $t("history") }}</span>
                  </div>
               </a>
            </router-link>
            <router-link tag="li" to="/polozky" class="menu" @click.native="toggleMobileMenu">
               <a class="dropdown-toggle" v-if="
                 this.$can('admin') ||
                 this.$can('office') ||
                 this.$can('technik-manager') ||
                 this.$can('obchodnik-manager') ||
                 this.$can('rola-pobocka') ||
                 this.$can('skladnik') ||
                 this.$can('kurier')
              ">
                  <div class="">
                     <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                        <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                        <polyline points="2 17 12 22 22 17"></polyline>
                        <polyline points="2 12 12 17 22 12"></polyline>
                     </svg>

                     <span>{{ $t("items") }}</span>
                  </div>
               </a>
            </router-link>
            <router-link tag="li" to="/polozky-pouzivatelov" class="menu" @click.native="toggleMobileMenu">
               <a class="dropdown-toggle" v-if="
                 this.$can('admin') ||
                 this.$can('office') ||
                 this.$can('technik-manager') ||
                 this.$can('obchodnik-manager') ||
                 this.$can('rola-pobocka') ||
                 this.$can('skladnik') ||
                 this.$can('kurier') ||
                 this.$can('infolinka') ||
                 this.$can('technik') ||
                 this.$can('obchodnik')
              ">
                  <div class="">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book-open">
                        <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
                        <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
                     </svg>
                     <span>{{ $t("users_items") }}</span>
                  </div>
               </a>
            </router-link>
            <router-link tag="li" to="/pouzivatelia" class="menu" @click.native="toggleMobileMenu">
               <a class="dropdown-toggle" v-if="this.$can('admin')">
                  <div class="">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-users" data-v-8d2239c6="">
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                     </svg>
                     <span>{{ $t("users") }}</span>
                  </div>
               </a>
            </router-link>
            <router-link tag="li" to="/navody" class="menu" @click.native="toggleMobileMenu">
               <a class="dropdown-toggle" v-if="
                 this.$can('admin') ||
                 this.$can('office') ||
                 this.$can('technik-manager') ||
                 this.$can('obchodnik-manager') ||
                 this.$can('rola-pobocka') ||
                 this.$can('skladnik') ||
                 this.$can('kurier') ||
                 this.$can('infolinka') ||
                 this.$can('technik') ||
                 this.$can('obchodnik')
              ">
                  <div class="">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-film" data-v-5522efca="">
                        <rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18"></rect>
                        <line x1="7" y1="2" x2="7" y2="22"></line>
                        <line x1="17" y1="2" x2="17" y2="22"></line>
                        <line x1="2" y1="12" x2="22" y2="12"></line>
                        <line x1="2" y1="7" x2="7" y2="7"></line>
                        <line x1="2" y1="17" x2="7" y2="17"></line>
                        <line x1="17" y1="17" x2="22" y2="17"></line>
                        <line x1="17" y1="7" x2="22" y2="7"></line>
                     </svg>
                     <span>{{ $t("navody") }}</span>
                  </div>
               </a>
            </router-link>
         </perfect-scrollbar>
      </nav>
   </div>
   <!--  END SIDEBAR  -->
</template>
<script>
   export default {
      data() {
         return { menu_collapse: "dashboard" };
      },

      watch: {
         $route(to) {
            if (document.querySelector("#sidebar a[href=\"" + to.path + "\"]")) {
               const selector = document.querySelector("#sidebar a[href=\"" + to.path + "\"]");
               const ul = selector.closest("ul.collapse");

               if (!ul) {
                  const ele = document.querySelector(".dropdown-toggle.not-collapsed");
                  if (ele) {
                     ele.click();
                  }
               }
            }

         }
      },
      mounted() {
         // default menu selection on refresh
         const selector = document.querySelector("#sidebar a[href=\"" + window.location.pathname + "\"]");
         if (selector) {
            const ul = selector.closest("ul.collapse");
            if (ul) {
               let ele = ul.closest("li.menu").querySelectorAll(".dropdown-toggle");
               if (ele) {
                  ele = ele[0];
                  setTimeout(() => {
                     ele.click();
                  });
               }
            } else {
               selector.click();
            }
         }
      },

      methods: {
         toggleMobileMenu() {
            if (window.innerWidth < 991) {
               this.$store.commit("toggleSideBar", true);
            }
         }
      }
   };
</script>
