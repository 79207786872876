<template>
   <div class="layout-px-spacing" v-if="
      this.$can('admin') ||
      this.$can('office') ||
      this.$can('technik-manager') ||
      this.$can('obchodnik-manager') ||
      this.$can('rola-pobocka') ||
      this.$can('skladnik') ||
      this.$can('kurier')
   ">
      <breadcrumb />

      <div class="row layout-top-spacing">
         <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
            <div class="panel p-0">
               <div class="custom-table table3">


                  <div class="table-header">
                     <h3>{{ $t('items') }}</h3>
                     <div class="header-search">
                        <a v-b-tooltip.hover :title="$t('create')" @click.prevent="add_item_modal" class="cursor-pointer">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-plus-circle">
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                           </svg>
                        </a>
                        <div class="header-search"><input type="text" v-model.trim="search_value"
                              :placeholder="$t('search')" class="form-control form-control-sm" id="__BVID__321">
                           <div class="search-image">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" class="feather feather-search">
                                 <circle cx="11" cy="11" r="8"></circle>
                                 <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                              </svg>
                           </div>
                        </div>

                     </div>

                  </div>

                  <div class="table-responsive">
                     <table class="table table-items table-hover" ref="table">
                        <thead>
                           <tr>
                              <th>Id</th>
                              <th class="expanding-th">{{ $t('name') }} <span @click="expand = !expand"
                                    v-if="expand === true">▶</span><span @click="expand = !expand" v-else>◀</span></th>
                              <th>{{ $t('category') }}</th>
                              <th>MJ</th>
                              <th>{{ $t('note') }}</th>
                              <th class="actions text-center">{{ $t('events') }}</th>
                           </tr>
                        </thead>
                        <tbody is="transition-group">
                           <tr v-if="items.length === 0" key="empty">
                              <td colspan="6" class="text-center">{{ $t('no_items') }}</td>
                           </tr>
                           <tr v-for="item in items" :key="item.id ?? 'new'">
                              <td tabindex="0" class="">{{ item.id }}</td>
                              <td>
                                 <div v-if="item.id === null" class="form-group">
                                    <b-input :state="validateState('item_name')" v-model="$v.form.item_name.$model"
                                       :placeholder="$t('item_name')"></b-input>
                                 </div>
                                 <span v-else class="expanding-span" :class="{ 'expanding-span-toogle': expand }">
                                    {{ item.name ?? "-" }}
                                 </span>
                              </td>
                              <td>
                                 <div v-if="item.id === null" class="form-group">
                                    <b-select :state="validateState('item_category')"
                                       v-model="$v.form.item_category.$model" :label="$t('category')">-->
                                       <b-select-option :value="null">{{ $t('category') }}</b-select-option>
                                       <b-select-option v-for="category in categories" :value="category.id"
                                          :key="category.id">
                                          {{ category.name ?? "-" }}
                                       </b-select-option>
                                    </b-select>
                                 </div>
                                 <span v-else>
                                    {{ item.category.name }}
                                 </span>
                              </td>
                              <td>
                                 <div v-if="item.id === null" class="form-group">

                                    <b-select :state="validateState('item_unit')" v-model="$v.form.item_unit.$model">-->
                                       <b-select-option :value="null">MJ</b-select-option>
                                       <b-select-option v-for="unit in units" :value="unit.id" :key="unit.id">
                                          {{ unit.name }}
                                       </b-select-option>
                                    </b-select>
                                 </div>
                                 <span v-else>
                                    {{ item.unit.name }}
                                 </span>
                              </td>
                              <td>
                                 <div v-if="item.id === null" class="form-group">
                                    <b-input :state="validateState('item_note')" v-model="$v.form.item_note.$model"
                                    :placeholder="$t('note')"></b-input>
                                 </div>
                                 <span v-else>
                                    {{ item.note ?? "-" }}
                                 </span>
                              </td>
                              <td class="action-btn text-center">
                                 <a v-if="item.id === null" href="" class="mr-1" v-b-tooltip.hover :title="$t('add')"
                                    @click.prevent="add_item()">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                       fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                       stroke-linejoin="round" class="feather feather-info">
                                       <circle cx="12" cy="12" r="10"></circle>
                                       <line x1="12" y1="16" x2="12" y2="12"></line>
                                       <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                    </svg>

                                 </a>
                                 <a v-else href="" class="mr-1" v-b-tooltip.hover :title="$t('edit')"
                                    @click.prevent="edit_item_modal(item.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                       fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                       stroke-linejoin="round" class="feather feather-edit-2 edit">
                                       <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                    </svg>
                                 </a>
                                 <a href="javascript:;" @click.prevent="delete_item(item.id)" v-b-tooltip.hover
                                 :title="$t('delete')">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                       fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                       stroke-linejoin="round" class="feather feather-trash-2">
                                       <polyline points="3 6 5 6 21 6"></polyline>
                                       <path
                                          d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                       </path>
                                       <line x1="10" y1="11" x2="10" y2="17"></line>
                                       <line x1="14" y1="11" x2="14" y2="17"></line>
                                    </svg>
                                 </a>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <!--                          <div class="loading-row" v-if="loading">-->
                     <!--                          </div>-->
                  </div>
                  <div class="table-footer">
                     <div v-if="items">{{ $t('it_is_displayed') }} {{ items.length }} {{ $t('of') }} {{ meta?.total }}
                     </div>
                     <div class="paginating-container pagination-solid flex-column align-items-right">
                        <b-pagination v-if="items && meta" prev-text="Prev" next-text="Next" first-text="First" last-text="Last"
                           first-class="first" prev-class="prev" next-class="next" last-class="last" class="rounded"
                           v-model="meta.current_page" :total-rows="meta?.total" :per-page="meta?.per_page"
                           @change="handlePageChange">
                           <template #first-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #prev-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M15 19l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #next-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M9 5l7 7-7 7" />
                              </svg>
                           </template>
                           <template #last-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                              </svg>
                           </template>
                        </b-pagination>
                     </div>
                  </div>
                  <!--                 <div class="table-footer">
                                     <div v-if="items">Zobrazuje se {{ filteredData.length }} z {{ items.total }}</div>
                                     <div class="paginating-container pagination-solid flex-column align-items-right">
                                       <b-pagination
                                           v-if="items"
                                           prev-text="Prev"
                                           next-text="Next"
                                           first-text="First"
                                           last-text="Last"
                                           first-class="first"
                                           prev-class="prev"
                                           next-class="next"
                                           last-class="last"
                                           class="rounded"
                                           v-model="items.current_page"
                                           :total-rows="items.total"
                                           :per-page="items.per_page"
                                           @change="handlePageChange"
                                       >
                                         <template #first-text>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                                           </svg>
                                         </template>
                                         <template #prev-text>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                                           </svg>
                                         </template>
                                         <template #next-text>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                           </svg>
                                         </template>
                                         <template #last-text>
                                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                                           </svg>
                                         </template>
                                       </b-pagination>
                                     </div>
                                   </div>-->
               </div>
            </div>
         </div>
      </div>

      <!-- Modal ADD-->
      <b-modal centered id="addItemModal" :static="true" :title="$t('create_an_item')" size="md">
         <div class="add-contact-box">
            <div class="add-contact-content">
               <b-form id="addItemModalTitle">
                  <div class="row">
                     <div class="col-md-12">
                        <div class="row">
                           <div class="col-12">
                              <div class="form-group">
                                 <b-input :state="validateState('item_name')" v-model="$v.form.item_name.$model"
                                 :placeholder="$t('item_name')"></b-input>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <b-select :state="validateState('item_category')"
                                    v-model="$v.form.item_category.$model" :label="$t('category')">
                                    <b-select-option :value="null">{{ $t('category') }}</b-select-option>
                                    <b-select-option v-for="category in categories" :value="category.id"
                                       :key="category.id">
                                       {{ category.name }}
                                    </b-select-option>
                                 </b-select>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <b-select :state="validateState('item_unit')" v-model="$v.form.item_unit.$model">
                                    <b-select-option :value="null">MJ</b-select-option>
                                    <b-select-option v-for="unit in units" :value="unit.id" :key="unit.id">
                                       {{ unit.name }}
                                    </b-select-option>
                                 </b-select>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <b-input :state="validateState('item_note')" v-model="$v.form.item_note.$model"
                                 :placeholder="$t('note')"></b-input>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </b-form>
            </div>
         </div>

         <template #modal-footer>
            <b-button variant="cancel" data-dismiss="modal" @click="cancel_item">{{ $t('cancel') }}</b-button>
            <b-button :class="{ activeButton: loading }" :disabled="loading" variant="default" @click="add_item">{{
               $t('create') }}
            </b-button>
         </template>
      </b-modal>

      <!-- Modal EDIT-->
      <b-modal centered id="editItemModal" :static="true" :title="$t('edit_an_item')" size="md">
         <div class="add-contact-box">
            <div class="add-contact-content">
               <b-form id="editItemModalTitle">
                  <div class="row">
                     <div class="col-md-12">
                        <div class="row">
                           <div class="col-12">
                              <div class="form-group">
                                 <b-input :state="validateState('item_name', false)" v-model="$v.form.item_name.$model"
                                 :placeholder="$t('item_name')"></b-input>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <b-select :state="validateState('item_category', false)"
                                    v-model="$v.form.item_category.$model" :label="$t('category')">
                                    <b-select-option :value="null">{{ $t('category') }}</b-select-option>
                                    <b-select-option v-for="category in categories" :value="category.id"
                                       :key="category.id">
                                       {{ category.name }}
                                    </b-select-option>
                                 </b-select>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <b-select :state="validateState('item_unit', false)" v-model="$v.form.item_unit.$model">
                                    <b-select-option :value="null">MJ</b-select-option>
                                    <b-select-option v-for="unit in units" :value="unit.id" :key="unit.id">
                                       {{ unit.name }}
                                    </b-select-option>
                                 </b-select>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <b-input :state="validateState('item_note', false)" v-model="$v.form.item_note.$model"
                                 :placeholder="$t('note')"></b-input>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </b-form>
            </div>
         </div>

         <template #modal-footer>
            <b-button variant="cancel" data-dismiss="modal" @click="cancel_item">{{ $t('cancel') }}</b-button>
            <b-button :class="{ activeButton: loading }" :disabled="loading" variant="default" @click.prevent="edit_item">
               Upraviť
            </b-button>
         </template>
      </b-modal>
   </div>
   <div class="layout-px-spacing d-flex align-items-center justify-content-center" v-else>
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center px-3">{{ $t('not_allowed') }}</h4>
         </div>
      </div>
   </div>
</template>

<script>
import "../../assets/sass/global/table.scss";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import _debounce from "lodash/debounce";

export default {
   mixins: [validationMixin],
   validations: {
      form: {
         item_name: {
            required
         },
         item_category: {
            required
         },
         item_unit: {
            required
         },
         item_note: {}
      }
   },
   data() {
      return {
         per_page: 100,
         search_value: "",
         page: 1,
         options: [
            { value: "5", text: "5" },
            { value: "10", text: "10" },
            { value: "20", text: "20" },
            { value: "50", text: "50" }
         ],
         form: {
            item_id: "",
            item_name: "",
            item_category: null,
            item_unit: null,
            item_note: ""
         },
         categories: {},
         units: {},
         expand: false
      };
   },
   watch: {
      search_value: {
         handler: _debounce(function () {
            this.fetchData();
         }, 250),
         immediate: false
      },
      per_page() {
         if (this.per_page > this.meta.total) {
            this.page = 1;
         }
         this.fetchData();
      }
   },
   created() {
      this.fetchData();
      this.$axios.get("item_categories")
         .then(response => {
            this.categories = response.data;
         });
      this.$axios.get("units")
         .then(response => {
            this.units = response.data;
         });
   },
   computed: {
      meta() {
         return this.$store.state.item.items_meta;
      },
      items() {
         return this.$store.state.item.items;
      },
      ...mapState("loader", ["loading"])
   },
   methods: {
      fetchData() {
         this.$store.dispatch("item/getItems", {
            page: this.page,
            per_page: this.per_page,
            search_value: this.search_value
         });
      },
      handlePageChange(value) {
         this.page = value;
         this.fetchData();
      },
      validateState(name, adding = true) {
         const { $dirty, $error } = adding ? this.$v.form[name] : this.$v.form[name];
         return $dirty ? !$error : null;
      },
      // scrollToLastRow() {
      //
      //   const rows = this.$refs.table.querySelector('tbody').querySelectorAll('tr')
      //   const row = rows[rows.length - 1]
      //   row.scrollIntoView(true)
      // },
      add_item_modal() {

         //if exists item where id== null, disable adding
         if (!this.$store.state.item.items.find(x => x.id == null)) {
            // this.$store.state.item.items.push({
            //   id: null,
            //   name: "",
            //   category: '',
            //   unit: '',
            //   note: ""
            // });
            this.form.item_id = "";
            this.form.item_name = "";
            this.form.item_category = null;
            this.form.item_unit = null;
            this.form.item_note = "";
            this.$v.$reset();
            this.$bvModal.show("addItemModal");

         }


         // this.$bvModal.show("addItemModal");
      },
      edit_item_modal(id) {
         /* this.$axios.get("items/"+id)
              .then((response) => {
                this.form.item_id = response.data.id;
                this.form.item_name = response.data.name;
                this.form.item_category = response.data.item_category_id;
                this.form.item_unit = response.data.unit_id;
                this.form.item_note = response.data.note;
              });
          */

         const itemData = this.items.find(x => x.id === id);

         this.form.item_id = itemData.id;
         this.form.item_name = itemData.name;
         this.form.item_category = itemData.category.id;
         this.form.item_unit = itemData.unit.id;
         this.form.item_note = itemData.note;
         this.$bvModal.show("editItemModal");

      },
      cancel_item() {
         this.$bvModal.hide("addItemModal");
         this.$bvModal.hide("editItemModal");
      },
      add_item() {
         this.$v.form.$touch();
         if (this.$v.form.$anyError) {
            this.$toast.error(this.$t("missing_credentials"));
            return;
         }

         let data = {
            name: this.form.item_name,
            item_category_id: this.form.item_category,
            unit_id: this.form.item_unit,
            note: this.form.item_note
         };

         // this.$store.dispatch("loader/pending");
         this.$axios.post("items", data)
            .then((response) => {
               // this.$bvModal.hide("addItemModal");
               this.$store.dispatch("item/getItems");
               // try {

               // let formItem = this.$store.state.item.items.find(x => x.id == null); // cez tuto premennu mi to nechcelo prekreslit
               // this.$store.state.item.items.find(x => x.id == null).name = response.data.item.name;
               // this.$store.state.item.items.find(x => x.id == null).category = response.data.item.category;
               // this.$store.state.item.items.find(x => x.id == null).unit = response.data.item.unit;
               // this.$store.state.item.items.find(x => x.id == null).note = response.data.item.note;
               // this.$store.state.item.items.find(x => x.id == null).id = response.data.item.id;
               // formItem.id = response.data.item.id;
               // formItem.name = response.data.item.name;
               // formItem.category = response.data.item.item_category;
               // formItem.unit = response.data.item.unit;
               // formItem.note = response.data.item.note;

               this.$bvModal.hide("addItemModal");
               this.$toast.success(response.data.message);
               // this.$store.dispatch("loader/done");
               // } catch (e) {
               //   console.log(e)
               // }
            })
            .catch(response => {
               this.$toast.error(response.response.data.message);
            });
      },
      edit_item() {
         this.$v.form.$touch();
         if (this.$v.form.$anyError) {
            this.$toast.error(this.$t("missing_credentials"));
            return;
         }

         let data = {
            item_id: this.form.item_id,
            name: this.form.item_name,
            item_category_id: this.form.item_category,
            unit_id: this.form.item_unit,
            note: this.form.item_note
         };

         this.$axios.put(`items/${this.form.item_id}`, data)
            .then((response) => {
               if (response !== undefined) {
                  this.$bvModal.hide("editItemModal");
                  this.$store.dispatch("item/getItems");
                  this.$toast.success(response.data.message);
               } else {
                  this.$toast.error(this.$t("match_find"));
               }
            })
           .catch(response => {
              this.$toast.error(response.response.data.message);
           });
      },
      delete_item(id) {
         if (id === null) {
            this.$store.state.item.items.pop();
         } else if (window.confirm("Opravdu to chceš vymazat?")) {
            this.$axios.delete(`items/${id}`)
               .then((response) => {
                  this.$store.dispatch("item/getItems");
                  this.$toast.success(response.data.message);
               }).catch(error => {
                  this.$toast.error(error.data.error);
               });
         }
      }
   }
};
</script>

<!--<style lang="scss">-->
<!--  @-webkit-keyframes moving-gradient {-->
<!--    0% { background-position: -250px 0; }-->
<!--    100% { background-position: 250px 0; }-->
<!--  }-->

<!--  .loading-row {-->

<!--      width: 100%;-->
<!--      height: 50px;-->
<!--      background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);-->
<!--      background-size: 500px 100px;-->
<!--      animation-name: moving-gradient;-->
<!--      animation-duration: 1s;-->
<!--      animation-iteration-count: infinite;-->
<!--      animation-timing-function: linear;-->
<!--      animation-fill-mode: forwards;-->

<!--  }-->
<!--</style>-->

<style scoped>
.table {
   white-space: nowrap;
}

.active-status {
   content: "";
   position: absolute;
   bottom: 7%;
   right: -4%;
   width: 30%;
   height: 30%;
   border-radius: 50%;
   border: 2px solid #fff;
   background-color: #1abc9c;
}

.usr-img-frame {
   position: relative;
}


.table-header h3 {
   margin-bottom: 0.6rem;

   @media (max-width: 576px) {
      margin-bottom: 1.2rem;
   }
}
</style>
