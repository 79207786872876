import axios from "axios";
import router from '../router'

export default {
    namespaced: true,
    state: {
        users: [],
        users_meta: [],
        user: []
    },
    mutations: {
        SET_USERS(state, users) {
            state.users = users.data ?? users
            state.users_meta = users.meta
        },
        SET_USER(state, user) {
            state.user = user
        }
    },
    actions: {
        getUsers({commit}, payload) {
            let params = {};
            if (payload !== null && payload !== undefined) {
                if (payload.page !== null && payload.page !== undefined) {
                    params['page'] = payload.page;
                }
                if (payload.per_page !== null && payload.per_page !== undefined) {
                    params['per_page'] = payload.per_page;
                }
                if (payload.search_value !== null && payload.search_value !== undefined) {
                    params['search_value'] = payload.search_value;
                }
            }

            axios.get('users', {params: new URLSearchParams(params)})
                .then(response => {
                    commit('SET_USERS', response.data)
                })
        },
        getAllUsers({commit}) {
            axios.get('users?all=1')
                .then(response => {
                    commit('SET_USERS', response.data)
                })
        },
        getUser({commit}) {
            axios.get('users/' + router.currentRoute.params.pouzivatel)
                .then(response => {
                    commit('SET_USER', response.data)
                })
        },
    },
}
