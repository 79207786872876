export function getStatusClass(statusValue) {
    return {
      created: 'badge-danger',
      ongoing: 'badge-warning',
      done: 'badge-success'
    }[statusValue];
  }
  
  export function getStatusTranslation(statusValue) {
    return `${statusValue}`;
  }
  