<template>
   <div class="layout-px-spacing" v-if="this.$can('admin')">
      <portal to="breadcrumb">
         <ul class="navbar-nav flex-row">
            <li>
               <div class="page-header">
                  <nav class="breadcrumb-one" aria-:label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:;">{{ $t('users') }}</a></li>
                        <li class="breadcrumb-item active" aria-current="page"><span>{{ $t('profile') }}</span></li>
                     </ol>
                  </nav>
               </div>
            </li>
         </ul>
      </portal>

      <div class="row layout-spacing">
         <div class="col-lg-12 layout-top-spacing">
            <div class="panel">
               <div class="panel-heading">
                  <div class="row">
                     <div class="col-12">
                        <h3>{{ $t('create_new_user') }}</h3> 
                     </div>
                  </div>
               </div>
               <div class="panel-body">
                  <b-form @submit.prevent="submitForm" ref="form">
                     <div class="row align-items-center mb-md-4">
                        <div class="col-12 mt-md-0 mt-4">
                           <b-form-row>
                              <b-form-group :label="$t('first_and_last_name')" class="col-md-4">
                                 <b-form-input :state="validateState('meno')" v-model="$v.form.meno.$model" type="text" :placeholder="$t('first_and_last_name')"></b-form-input>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group :label="$t('email')" class="col-md-4">
                                 <b-input :state="validateState('email')" v-model="$v.form.email.$model" type="email" :placeholder="$t('email')"></b-input>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group :label="$t('password')" class="col-md-4">
                                 <b-input :state="validateState('heslo')" v-model="$v.form.heslo.$model" type="password" :placeholder="$t('password')"></b-input>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row>
                              <b-form-group class="col-md-4" :label="$t('town')">
                                 <b-select :state="validateState('selectedTown')" v-model="$v.form.selectedTown.$model">
                                    <b-select-option :value="null">{{ $t('select') }}</b-select-option>
                                    <b-select-option v-for="town in towns" :value="town.id" :key="town.id">
                                       {{ town.town_name }}
                                    </b-select-option>
                                 </b-select>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group class="col-md-4" :label="$t('role')">
                                 <b-select :state="validateState('selectedRola')" v-model="$v.form.selectedRola.$model">
                                    <b-select-option :value="null">{{ $t('select') }}</b-select-option>
                                    <b-select-option v-for="rola in roles" :value="rola.id" :key="rola.id">
                                       {{ rola.name }}
                                    </b-select-option>
                                 </b-select>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group class="col-md-4" :label="$t('language')">
                                 <b-select :state="validateState('language')" v-model="$v.form.language.$model">
                                    <b-select-option :value="null">{{ $t('select') }}</b-select-option>
                                    <b-select-option value="en" v-if="this.$i18n.locale === 'en'">{{ $t('en') }}</b-select-option>
                                    <b-select-option value="sk" v-if="this.$i18n.locale === 'sk'">{{ $t('sk') }}</b-select-option>
                                    <b-select-option value="cz" v-if="this.$i18n.locale === 'cz'">{{ $t('cz') }}</b-select-option>
                                    <b-select-option value="pl" v-if="this.$i18n.locale === 'pl'">{{ $t('pl') }}</b-select-option>
                                    <b-select-option value="hu" v-if="this.$i18n.locale === 'hu'">{{ $t('hu') }}</b-select-option>
                                 </b-select>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row>
                              <b-form-group class="col-12" :label="$t('assigned_technicians')">
<!--                                <b-select-->
<!--                                    v-model="form.value"-->
<!--                                    name="" id="" class="form-select form-control">-->
<!--                                  <option v-for="item in users" :key="item.pouzivatel.id" :value="item.id">-->
<!--                                    {{item}}-->
<!--&lt;!&ndash;                                    {{ item.pouzivatel.name }}&ndash;&gt;-->
<!--                                  </option>-->
<!--                                </b-select>-->
<!--                                {{users}}-->
                                 <multiselect :showNoResults="false" v-model="form.value" :deselectLabel="$t('clear')" :selectLabel="$t('select')" :selectedLabel="$t('selected')"   :tag-placeholder="$t('add_as_new_tag')" :placeholder="$t('search')" label="name" track-by="id" :options="users" :multiple="true" :closeOnSelect="false"></multiselect>
                              </b-form-group>
                           </b-form-row>
                          <b-form-row>
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="form.default_store">
                                {{ $t('default_warehouse') }}
                            </b-form-checkbox>
                          </b-form-row>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-12 text-right">
                           <b-button @click="$router.go(-1)" variant="cancel" class="mt-3">{{ $t('cancel') }}</b-button>
                           <b-button type="submit" variant="default" class="mt-3 ml-2">{{ $t('create') }}</b-button>
                        </div>
                     </div>
                  </b-form>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="layout-px-spacing d-flex align-items-center justify-content-center" v-else>
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center px-3">{{ $t('not_allowed') }}</h4>
         </div>
      </div>
   </div>
</template>

<script>
   import "vue-multiselect/dist/vue-multiselect.min.css";
   import { validationMixin } from "vuelidate";
   import { required, email } from "vuelidate/lib/validators";
   import Multiselect from "vue-multiselect";


   export default {
      name: "new",
      mixins: [validationMixin],
      components: {
         Multiselect
      },
      validations: {
         form: {
            meno: {
               required
            },
            email: {
               required,
               email
            },
            heslo: {
               required
            },
            selectedRola: {
               required
            },
            selectedTown: {
               required
            },
            language: {
               required
            }
         }
      },
      data() {
         return {
            roles: {},
            users: [],
            towns: {},
            form: {
               meno: "",
               email: "",
               heslo: "",
               language: this.$i18n.locale || "en",
               selectedRola: null,
               selectedTown: null,
              default_store: false,
               value: []
            }
         };
      },
      created() {

         this.$axios.get("users?all=1")
           .then((response) => {
              this.users = response.data;
           });

         this.$axios.get("roles")
           .then((response) => {
              this.roles = response.data;
           });

         this.$axios.get("towns")
           .then((response) => {
              this.towns = response.data;
           });


      },
      methods: {
         validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
         },
         submitForm() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
               this.$toast.error(this.$t("missing_credentials"));
               return;
            }

            let data = {
               name: this.form.meno,
               email: this.form.email,
               password: this.form.heslo,
               phone: this.form.cislo,
               language: this.form.language,
               town_id: this.form.selectedTown,
               rola: this.form.selectedRola,
               default_store: this.form.default_store,
               technicians_ids: this.form.value.map(item => item.id)
            };

            let formData = new FormData();

            for (let key in data) {
               formData.append(key, data[key]);
            }

            this.$axios.post("users", formData).then((response) => {
               this.$router.go(- 1);
               this.$toast.success(response.data.message);
            }).catch(error => {
               this.$store.dispatch("loader/done")
               this.$toast.error(error.response.data.message);
            });
         }
      }
   };
</script>
