<template>
   <div class="layout-px-spacing" v-if="
        this.$can('admin') ||
        this.$can('office') ||
        this.$can('technik-manager') ||
        this.$can('obchodnik-manager') ||
        this.$can('rola-pobocka') ||
        this.$can('skladnik') ||
        this.$can('kurier') ||
        this.$can('infolinka') ||
        this.$can('technik')
   ">
      <portal to="breadcrumb">
         <ul class="navbar-nav flex-row">
            <li>
               <div class="page-header">
                  <nav class="breadcrumb-one" aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:;">{{ $t('users') }}</a></li>
                        <li class="breadcrumb-item active" aria-current="page"><span>{{ $t('profile') }}</span></li>
                     </ol>
                  </nav>
               </div>
            </li>
         </ul>
      </portal>

      <div class="row layout-spacing">
         <div class="col-lg-12 layout-top-spacing">
            <div class="panel">
               <div class="panel-heading">
                  <div class="row">
                     <div class="col-12">
                        <h3>{{ $t('create_order') }}</h3>
                     </div>
                  </div>
               </div>
               <div class="panel-body">
                  <b-form @submit.prevent="submitForm" ref="form">
                     <div class="row align-items-center mb-md-4">
                        <div class="col-12 mt-0">
                           <b-form-row class="over-1">
                              <b-form-group :label="$t('order_name')" class="col-md-12">
                                 <b-form-input :state="validateState('name')" v-model="$v.form.name.$model" type="text"
                                               placeholder=""></b-form-input>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group :label="$t('identifier')" class="col-md-6 d-none">
                                 <b-form-input :state="validateState('identifier')" v-model="$v.form.identifier.$model" type="text"
                                 :placeholder="$t('if_not_filled')"></b-form-input>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>
                           </b-form-row> 
                           <b-form-row class="over-1">
                              <b-form-group :label="$t('customer')" class="col-md-6">
                                 <b-form-input v-if="form.from_user_name && !$can('admin') && !$can('office') && !$can('technik-manager') && !$can('obchod-manager') && !$can('rola-pobocka')" :state="validateState('from_user_name')" v-model="form.from_user_name.name" type="text" readonly placeholder=""></b-form-input>
                                 <multiselect v-else :max-height="200" :showNoResults="false" :state="validateState('from_user_id')" v-model="$v.form.from_user_id.$model" :deselectLabel="$t('clear')" :selectLabel="$t('select')" :selectedLabel="$t('selected')" :tag-placeholder="$t('add_as_new_tag')" :placeholder="$t('search')" label="name" track-by="id" :can-deselect="false" :allow-empty="false" :options="users" :multiple="false" :closeOnSelect="true"></multiselect>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>

                              <b-form-group class="col-md-6" :label="$t('supplier')">
                                 <multiselect :max-height="200" :showNoResults="false" :state="validateState('to_user_id')" v-model="$v.form.to_user_id.$model" :deselectLabel="$t('clear')" :selectLabel="$t('select')" :selectedLabel="$t('selected')" :tag-placeholder="$t('add_as_new_tag')" :placeholder="$t('search')" label="name" track-by="id" :can-deselect="false" :allow-empty="false" :options="usersWithoutMe" :multiple="false" :closeOnSelect="true"></multiselect>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row>
                              <b-form-group class="col-md-12" :label="$t('products')">
                                 <div class="form-repeater w-100 custom-table">
                                    <table class="table table-responsive-detail">
                                       <thead>
                                          <tr>
                                             <th style="width: 30%;min-width: 200px">{{ $t('name') }}</th>
                                             <th style="width: 30%;min-width: 200px">{{ $t('count') }}</th>
                                             <th style="width: 30%;min-width: 200px">{{ $t('note') }}</th>
                                             <th class="text-center">{{ $t('delete') }}</th>
                                          </tr>
                                       </thead>
                                       <tbody class="form-repeater">
                                          <tr v-for="(row, index) in form.rows" :key="index">
                                             <td>
                                                <multiselect v-if="row.id != null" :max-height="200" :showNoResults="false" v-model="row.id" :deselectLabel="$t('clear')" :selectLabel="$t('select')" :selectedLabel="$t('selected')" :tag-placeholder="$t('add_as_new_tag')" :placeholder="$t('search')" label="name" track-by="id" :can-deselect="false" :allow-empty="false" :options="items" :multiple="false" :closeOnSelect="true"></multiselect>
                                                <b-form-input v-else v-model="row.name" type="text" :placeholder="$t('enter_item_name')"></b-form-input>
                                             </td>
                                             <td>
                                                <b-input-group v-if="row.id != null">
                                                   <template #append>
                                                      <b-input-group-text><strong v-if="row.id" class="">{{ row.id.unit.name }}</strong></b-input-group-text>
                                                   </template>
                                                   <b-form-input v-model="row.quantity" type="number" min="0" placeholder=""></b-form-input>
                                                </b-input-group>
                                                <b-input-group v-else>
                                                   <template #append>
                                                      <multiselect class="unit-multiselect" :max-height="200" :showNoResults="false" v-model="row.unit" :deselectLabel="$t('clear')" :selectLabel="$t('select')" :selectedLabel="$t('selected')" :tag-placeholder="$t('add_as_new_tag')" :placeholder="$t('search')" label="name" track-by="id" :can-deselect="false" :allow-empty="false" :options="units" :multiple="false" :closeOnSelect="true"></multiselect>
                                                   </template>
                                                   <b-form-input v-model="row.quantity" type="number" min="0" placeholder=""></b-form-input>
                                                </b-input-group>
                                             </td>
                                             <td>
                                                <b-form-input v-model="row.note" type="text" placeholder=""></b-form-input>
                                             </td>
                                             <td class="text-center align-middle">
                                                <b-button variant="outline-danger" @click="removeRow(index)">
                                                   <svg
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="24"
                                                     height="24"
                                                     viewBox="0 0 24 24"
                                                     fill="none"
                                                     stroke="currentColor"
                                                     stroke-width="2"
                                                     stroke-linecap="round"
                                                     stroke-linejoin="round"
                                                     class="feather feather-trash-2"
                                                   >
                                                      <polyline points="3 6 5 6 21 6"></polyline>
                                                      <path
                                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                      <line x1="10" y1="11" x2="10" y2="17"></line>
                                                      <line x1="14" y1="11" x2="14" y2="17"></line>
                                                   </svg>
                                                </b-button>
                                             </td>
                                          </tr>

                                          <tr class="text-md-center">
                                             <td colspan="6">
                                                <b-button variant="outline-dark" @click="addRow" class="mr-2">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-plus-circle">
                                                      <circle cx="12" cy="12" r="10"></circle>
                                                      <line x1="12" y1="8" x2="12" y2="16"></line>
                                                      <line x1="8" y1="12" x2="16" y2="12"></line>
                                                   </svg>
                                                </b-button>
                                                <b-button variant="outline-dark" @click="addRowCustom" v-if="$can('admin') || $can('office') || $can('technik-manager') || $can('obchod-manager') || $can('rola-pobocka')">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-plus-circle">
                                                      <circle cx="12" cy="12" r="10"></circle>
                                                      <line x1="12" y1="8" x2="12" y2="16"></line>
                                                      <line x1="8" y1="12" x2="16" y2="12"></line>
                                                   </svg>
                                                   {{ $t('custom') }}
                                                </b-button>

                                             </td>

                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </b-form-group>
                           </b-form-row>

                           <b-form-row>
                              <b-form-group class="col-md-12" :label="$t('global_note')">
                                 <b-form-textarea id="textarea" v-model="$v.form.note.$model" :placeholder="$t('note')"
                                                  rows="4"></b-form-textarea>
                              </b-form-group>
                           </b-form-row>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-12 text-right">
                           <b-button @click="$router.go(-1)" variant="danger" class="mt-3">{{ $t('cancel') }}</b-button>
                           <b-button type="submit" variant="primary" class="mt-3 ml-2">{{ $t('create') }}</b-button>
                        </div>
                     </div>
                  </b-form>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="layout-px-spacing d-flex align-items-center justify-content-center" v-else>
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center px-3">{{ $t('not_allowed') }}</h4>
         </div>
      </div>
   </div>
</template>

<script>
   import { validationMixin } from "vuelidate";
   import { required } from "vuelidate/lib/validators";
   import Multiselect from "vue-multiselect";
   import "vue-multiselect/dist/vue-multiselect.min.css";
   import "../../assets/sass/global/table.scss";

   export default {
      name: "new",
      mixins: [validationMixin],
      components: { Multiselect },

      validations: {
         form: {
            name: {},
            identifier: {},
            from_user_id: {
               required
            },
            from_user_name: {
               required
            },
            to_user_id: {
               required
            },
            note: {},
            rows: {
               required
            }
         }
      },
      data() {
         return {
            form: {
               name: "",
               identifier: null,
               from_user_name: null,
               from_user_id: null,
               to_user_id: null,
               note: "",
               rows: [{ id: 0, name: "", quantity: "", note: "", unit: "" }]
            },
            units: {},
            errors: []
         };
      },
      created() {
         this.$axios.get("user")
           .then(response => {
              if (response.data) {
                 this.form.from_user_id = response.data.pouzivatel;
                 this.form.from_user_name = response.data.pouzivatel;

              }
           });
         this.$store.dispatch("user/getAllUsers");
         this.$store.dispatch("item/getItems", {all: 1});

         this.$axios.get("units")
           .then(response => {
              this.units = response.data;
           });


         //DORIEŠIŤ
         const user = this.users.filter(user => user.default_store === true);
         this.form.to_user_id = user[0];
         /*setTimeout(function () {
           if(this.users) {

           }

         }, 1500);*/

      },
      computed: {
         users() {
            return this.$store.state.user.users;
         },
         usersWithoutMe() {
            return this.$store.state.user.users.filter(user => user.id !== this.form.from_user_id);
         },
         items() {
            return this.$store.state.item.items;
         }
      },
      methods: {
         addRow() {
            this.form.rows.push({ id: 0, name: "", quantity: "", note: "", unit: "" });
         },
         addRowCustom() {
            this.form.rows.push({ id: null, name: "", quantity: "", note: "", unit: "" });
         },
         removeRow(index) {
            this.form.rows.splice(index, 1);
         },
         validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
         },
         submitForm() {
            const t = this;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
               t.$toast.error(t.$t("product_or_supplier_missing"));
               return;
            }

            this.form.rows.forEach(function(value) {
               if (value.id === 0) {
                  t.errors.push(t.$t("missing_product"));
                  if (value.quantity === "") {
                     t.errors.push(t.$t("missing_quantity"));
                  }
               } else if (value.id !== 0 && value.id !== null) {
                  if (value.quantity === "") {
                     t.errors.push(t.$t("missing_quantity"));
                  }
               } else if (value.id === null && value.name === "") {
                  t.errors.push(t.$t("missing_product_name"));
                  if (value.quantity === "") {
                     t.errors.push(t.$t("missing_quantity"));
                  }
                  if (value.unit === "") {
                     t.errors.push(t.$t("missing_unit"));
                  }
               } else if (value.id === null && value.name !== "") {
                  if (value.quantity === "") {
                     t.errors.push(t.$t("missing_quantity"));
                  }
                  if (value.unit === "") {
                     t.errors.push(t.$t("missing_unit"));
                  }
               }
            });


            if (t.errors.length > 0) {
               t.$toast.error(t.errors[0]);
               t.errors = [];
            } else {
               this.$v.form.$touch();
               if (this.$v.form.$anyError) {
                  t.$toast.error(t.$t("missing_credentials"));
                  return;
               }

               let data = {
                  name: this.form.name,
                  identifier: this.form.identifier,
                  from_user_id: this.form.from_user_id.id,
                  to_user_id: this.form.to_user_id.id,
                  note: this.form.note
               };

               let formData = new FormData();

               for (let key in data) {
                  formData.append(key, data[key]);
               }


               this.form.rows.forEach(function(value, index) {
                  if (value.id !== null) {
                     formData.append("items[" + index + "][name]", "");
                     formData.append("items[" + index + "][id]", value.id.id);
                  } else {
                     formData.append("items[" + index + "][name]", value.name);
                     formData.append("items[" + index + "][id]", null);
                     formData.append("items[" + index + "][unit_id]", value.unit.id);

                  }
                  formData.append("items[" + index + "][quantity]", value.quantity);
                  formData.append("items[" + index + "][note]", value.note);
               });

               this.$axios.post("orders", formData)
                 .then((response) => {

                    if (response.status === 200 || response.status === 201) {
                       this.$router.go(- 1);
                       t.$toast.success(response.data.message);
                    } else {
                       console.log(response);
                       t.$toast.error(response.data.message);

                    }
                    // console.log(response)
                    // t.$toast.success(response.data.message);

                 }).catch(error => {
                  console.log(error);
                  t.$toast.error(error.response.data.message);
               });
            }


         }
      }
   };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
   .unit-multiselect {

      .multiselect__tags {
         border-radius: 0 0.2rem 0.2rem 0;
         background: #f1f2f3;
         min-height: 45.4px !important;
         border: 1px solid #bfc9d4;

      }

      .multiselect__single,
      .multiselect__input {
         background: #f1f2f3;
      }
   }
</style>
