<template>
   <div class="layout-px-spacing">
      <breadcrumb />

      <div class="row layout-top-spacing">
         <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
            <div class="panel p-0">
               <div class="custom-table table3">
                  <div class="table-header">
                     <h3>{{ $t('transfer_history') }}</h3>
                     <div class="">
                        <div class="header-search">
                           <div class="header-search">
                              <input type="text" v-model.trim="metaLocal.movements.search_value"
                                     :placeholder="$t('search')"
                                     class="form-control form-control-sm"
                                     id="__BVID__321">
                              <div class="search-image">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                      class="feather feather-search">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                 </svg>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="table-responsive">
                     <table class="table table-hover">
                        <thead>
                           <tr>
                              <th>Id</th>
                              <th>{{ $t('type') }}</th>
                              <th>{{ $t('order') }}</th>
                              <th>{{ $t('supplier') }}</th>
                              <th>{{ $t('recipient') }}</th>
                              <th>{{ $t('date') }}</th>
                              <th>{{ $t('note') }}</th>
                              <th>{{ $t('items') }}</th>
                           </tr>
                        </thead>
                        <tbody is="transition-group">
                           <tr v-if="movements.length === 0" key="empty">
                              <td colspan="8" class="text-center">{{ $t('no_items') }}</td> 
                           </tr>
                           <tr v-for="item in movements" :key="item.id">
                              <td tabindex="0" class="">{{ item.id }}</td>
                              <td>
                                 <b-badge
                                   :variant="item.type === 'IN'|| item.type === 'SELF_IN' ? 'success' : 'warning'">
                                    {{ item.type_value }}
                                 </b-badge>
                              </td>
                              <td>
                                 <router-link v-if="item.order != null" :to="'/objednavky/' + item.order.id + '/detail'" class="badge inv-status badge-dark"
                                              v-b-tooltip.hover :title="$t('detail')">{{ item.order.identifier + (item.order.name != null ? " - " + item.order.name : "") }}
                                 </router-link>
                                 <span v-else>
                                    -
                                 </span>
                              </td>
                              <td>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user placeholder">
                                    <path data-v-17470ab0="" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle data-v-17470ab0="" cx="12" cy="7" r="4"></circle>
                                 </svg>
                                 {{ item.from_user != null ? item.from_user.name : item.other_user_name }}
                              </td>
                              <td>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user placeholder">
                                    <path data-v-17470ab0="" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle data-v-17470ab0="" cx="12" cy="7" r="4"></circle>
                                 </svg>
                                 {{ item.to_user != null ? item.to_user.name : item.other_user_name }}
                              </td>
                              <td>{{ item.created_at }}</td>
                              <td>{{ item.note != null && item.note !== "" ? item.note : "-" }}</td>
                              <td>
                                 <b-button @click="openItemsModal(item)" variant="outline-primary btn-sm">{{ $t('items') }}</b-button>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <!--                          <div class="loading-row" v-if="loading">-->
                     <!--                          </div>-->
                  </div>
                  <div class="table-footer">
                     <div v-if="movements">{{ $t('it_is_displayed') }} {{ movements.length }} {{ $t('of') }} {{ movements_meta.total }}</div>
                     <div class="paginating-container pagination-solid flex-column align-users-right">
                        <b-pagination
                          v-if="movements"
                          prev-text="Prev"
                          next-text="Next"
                          first-text="First"
                          last-text="Last"
                          first-class="first"
                          prev-class="prev"
                          next-class="next"
                          last-class="last"
                          class="rounded"
                          v-model="movements_meta.current_page"
                          :total-rows="movements_meta.total"
                          :per-page="movements_meta.per_page"
                          @change="handleMovementsPageChange"
                        >
                           <template #first-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                       d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #prev-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #next-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                              </svg>
                           </template>
                           <template #last-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"  
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                       d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                              </svg>
                           </template>
                        </b-pagination>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>

      <b-modal centered id="movementItemsModal" :static="true" :title="$t('transfer_items')" size="lg">
         <div v-if="actualMovement != null" class="table-responsive custom-table">
            <table class="table table-hover">
               <thead>
                  <tr>
                     <th>Id</th>
                     <th>{{ $t('name') }}</th>
                     <!--              <th>MJ</th>-->
                     <th>{{ $t('count') }}</th>
                     <th style="min-width: 17rem;" v-if="actualMovement.items.some(item => item.attachments && item.attachments.length > 0)">{{ $t('images') }}</th>
                     <th>{{ $t('note') }}</th>
                  </tr>
               </thead>
               <tbody is="transition-group">
                  <tr v-if="actualMovement.items.length === 0" key="empty">
                     <td colspan="6" class="text-center">{{ $t('no_items') }}</td>
                  </tr>
                  <tr v-for="(item, itemIndex) in actualMovement.items" :key="item.id">
                     <td tabindex="0" class="">{{ item.id }}</td>
                     <td>{{ item.name }}</td>
                     <!--              <td>{{ item.unit }}</td>-->
                     <td>{{ item.quantity }}</td>
                     <td v-if="actualMovement.items.some(itemm => itemm.attachments && itemm.attachments.length > 0)">
                        <div class="image-wrapper" v-if="item.attachments">
                           <img v-for="(image, index) in item.attachments"
                                :key="index"
                                :src="url + '/' + image"
                                alt="img"
                                @click="() => showImg(getImageIndex(itemIndex, index))">
                        </div>
                        <div v-else>-</div>
                     </td>

                     <td>{{ item.note ? item.note : "-" }}</td>

                  </tr>
               </tbody>
            </table>
            <!--                          <div class="loading-row" v-if="loading">-->
            <!--                          </div>-->
         </div>

         <template #modal-footer>
            <b-button variant="cancel" data-dismiss="modal" @click="cancel_item">{{ $t('cancel') }}</b-button>
         </template>
      </b-modal>

      <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
   </div>
</template>

<script>
   import "../../assets/sass/global/table.scss";
   import { validationMixin } from "vuelidate";
   import { required } from "vuelidate/lib/validators";
   import { mapState } from "vuex";
   import _debounce from "lodash/debounce";
   import { urlMixin } from "../../mixins/url";

   export default {
      mixins: [validationMixin, urlMixin],
      validations: {
         form: {
            item_name: {
               required
            },
            item_category: {
               required
            },
            item_unit: {
               required
            },
            item_note: {}
         }
      },
      data() {
         return {
            metaLocal: {
               movements: {
                  per_page: 10,
                  search_value: "",
                  page: 1
               }
            },
            options: [
               { value: "5", text: "5" },
               { value: "10", text: "10" },
               { value: "20", text: "20" },
               { value: "50", text: "50" }
            ],
            actualMovement: null,
            visible: false,
            imgs: [],
            index: null
         };
      },
      watch: {
         "metaLocal.items.search_value": {
            handler: _debounce(function() {
               this.metaLocal.items.page = 1;
               this.fetchItemsData();
            }, 250)
         },
         "metaLocal.movements.search_value": {
            handler: _debounce(function() {
               this.metaLocal.movements.page = 1;
               this.fetchMovementData();
            }, 250)
         },
         "metaLocal.items.per_page": {
            handler: _debounce(function() {
               if (this.metaLocal.items.per_page > this.items_meta.total) {
                  this.metaLocal.items.page = 1;
               }
               this.fetchItemsData();
            }, 250)
         },
         "metaLocal.movements.per_page": {
            handler: _debounce(function() {
               if (this.metaLocal.movements.per_page > this.items_meta.total) {
                  this.metaLocal.movements.page = 1;
               }
               this.fetchMovementData();
            }, 250)
         }
      },
      created() {
         this.fetchMovementData();
      },
      computed: {
         items() {
            return this.$store.state.storage.items;
         },
         items_meta() {
            return this.$store.state.storage.items_meta;
         },
         movements() {
            return this.$store.state.storage.movements;
         },
         movements_meta() {
            return this.$store.state.storage.movements_meta;
         },
         ...mapState("loader", ["loading"])
      },
      methods: {
         getImageIndex(itemIndex, imageIndex) {
            let totalIndex = 0;

            for (let i = 0; i < itemIndex; i ++) {
               const item = this.actualMovement.items[i];
               if (item.attachments) {
                  totalIndex += item.attachments.length;
               }
            }

            return totalIndex + imageIndex;
         },
         fetchItemsData() {
            this.$store.dispatch("storage/getItems", {
               page: this.metaLocal.items.page,
               per_page: this.metaLocal.items.per_page,
               search_value: this.metaLocal.items.search_value
            });
         },
         fetchMovementData() {
            this.$store.dispatch("storage/getMovementsOfAllUsers", {
               page: this.metaLocal.movements.page,
               per_page: this.metaLocal.movements.per_page,
               search_value: this.metaLocal.movements.search_value
            });
         },
         handleMovementsPageChange(value) {
            this.metaLocal.movements.page = value;
            this.fetchMovementData();
         },
         handleItemsPageChange(value) {
            this.metaLocal.items.page = value;
            this.fetchItemsData();
         },
         openItemsModal(movement) {
            this.actualMovement = movement;
            this.imgs = movement.items.flatMap(item => item.attachments ? item.attachments.map(image => this.url + "/" + image) : []);
            this.$bvModal.show("movementItemsModal");
         },
         cancel_item() {
            this.$bvModal.hide("movementItemsModal");
         },
         showImg(index) {
            this.index = index;
            this.visible = true;
         },
         handleHide() {
            this.visible = false;
         }
      }
   };
</script>

<style scoped lang="scss">
   .table {
      white-space: nowrap;
   }


   .active-status {
      content: "";
      position: absolute;
      bottom: 7%;
      right: -4%;
      width: 30%;
      height: 30%;
      border-radius: 50%;
      border: 2px solid #fff;
      background-color: #1abc9c;
   }


   .usr-img-frame {
      position: relative;
   }


   .table-header h3 {
      margin-bottom: 0.6rem;

      @media (max-width: 576px) {
         margin-bottom: 1.2rem;
      }
   }


   .image-wrapper {
      display: flex;
      align-items: center;
      gap: 0.4rem;

      > img {
         width: 6rem;
         height: 4rem;
         object-fit: cover;
         border-radius: 0.5rem;
         transition: .2s;
         cursor: pointer;
         flex-wrap: wrap;

         @media all and (max-width: 500px) {
            width: 4rem;
            height: 3rem;
         }

         &:hover {
            transform: scale(1.03);
            border-radius: 0.5rem;
         }
      }
   }
</style>
