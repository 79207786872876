<template>
   <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center p-3">{{ $t('instructions') }}</h4>
            <div class="iframe-container">
               <iframe :src="currentVideoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   data() {
      return {
         //TODO: Add actual video IDs
         videos: {
            sk: 'https://www.youtube.com/embed/c4GG8V2xcO4?si=bPvONfHDUsPD2PSg',
            pl: 'https://www.youtube.com/embed/c4GG8V2xcO4?si=bPvONfHDUsPD2PSg',
            hu: 'https://www.youtube.com/embed/c4GG8V2xcO4?si=bPvONfHDUsPD2PSg',
            cz: 'https://www.youtube.com/embed/c4GG8V2xcO4?si=bPvONfHDUsPD2PSg',
            en: 'https://www.youtube.com/embed/c4GG8V2xcO4?si=bPvONfHDUsPD2PSg'
         }
      };
   },
   computed: {
      currentVideoUrl() {
         // Extract language from URL
         const language = window.location.hostname.split('.')[0];
         return this.videos[language] || this.videos.en;
      }
   }
};
</script>

<style scoped>
   .iframe-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
   }

   iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
   }
</style>
