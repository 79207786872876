import axios from "axios";
import router from '../router'

export default {
    namespaced: true,
    state: {
        // contracts_all: [],
        contracts: [],
        contract: []
    },
    mutations: {
        SET_CONTRACTS(state, contracts) {
            state.contracts = contracts
        },
        // SET_CONTRACTS_ALL(state, contracts_all) {
        //     state.contracts_all = contracts_all
        // },
        SET_CONTRACT(state, contract) {
            state.contract = contract
        },
        // ADD_CONTRACT(state, contract) {
        //     state.contracts.push(contract)
        // }
    },
    actions: {
        getContracts({ commit }, page) {
            axios.get(`contracts?page=${page}`)
                .then(response => {
                    commit('SET_CONTRACTS', response.data)
                })
        },
        // getContractsAll({ commit }) {
        //     axios.get(`contracts_all`)
        //         .then(response => {
        //             commit('SET_CONTRACTS_ALL', response.data)
        //         })
        // },
        getContract({ commit }) {
            axios.get('contracts/' + router.currentRoute.params.contract)
                .then(response => {
                    commit('SET_CONTRACT', response.data)
                })
        },
    }
}
