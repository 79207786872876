<script>
    export default {
        methods: {
            $can(permissionName) {
                if(this.$store.state.auth.userData) {
                    if(this.$store.state.auth.userData.rola[0].indexOf(permissionName) !== -1) {
                        return  true
                    } else {
                        return false
                    }
                }

            },
          can(permissionName) {
            this.$can(permissionName);
          }
        },
    };
</script>
