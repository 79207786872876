<template>
   <div class="width-100">
      <div class="gallery width-100">
         <!--                <Loader-->
         <!--                    color="#0275d8"-->
         <!--                    :active="loading"-->
         <!--                    spinner="line-scale"-->
         <!--                    background-color = 'rgba(255, 255, 255, .4)'-->
         <!--                />-->
         <div class="elements-wraper">

            <!--UPLOAD BUTTON-->
            <div class="button-container image-margin">
               <label :for="'images-upload' + sectionId" class="images-upload" :class="{ 'images-upload-disabled': media.length >= 3 }">
                  <svg
                    class="custum-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24">
                     <g fill="none">
                        <path
                          d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1zm1 15a1 1 0 1 1-2 0v-3H8a1 1 0 1 1 0-2h3V8a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2h-3v3z"
                          fill="currentColor" />
                     </g>
                  </svg>
               </label>
               <input @change="fileChange" :id="'images-upload' + sectionId" type="file" accept="image/png, image/jpeg" hidden multiple :disabled="media.length >= 3">
            </div>

            <!--IMAGES PREVIEW-->
            <div v-for="(image, index) in media" :key="index" class="image-container image-margin">
               <img :src="urlTmpAttachments + image.name" alt="" class="images-preview">
               <button @click="remove(index)" class="close-btn" type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle" data-v-5522efca="">
                     <circle cx="12" cy="12" r="10"></circle>
                     <line x1="15" y1="9" x2="9" y2="15"></line>
                     <line x1="9" y1="9" x2="15" y2="15"></line>
                  </svg>
               </button>
            </div>
         </div>
      </div>
      <!--            <div v-if='error' id="media-required">-->
      <!--                <p class='red-text'>{{error}}</p>-->
      <!--            </div>-->
      <div v-for="(image, index) in media" :key="index" class="m-top">
         <input type="text" name="media[]" :value="image.name" hidden>
      </div>

   </div>
</template>

<script>
   import Compressor from "compressorjs";
   // import Loader from './loader/index.vue'
   import "../../assets/sass/uploadMedia/upload.scss";
   import { urlTmpAttachments } from "../../mixins/urlTmpAttachments";

   export default {
      data() {
         return {
            media: [],
            loading: false
         };
      },
      mixins: [urlTmpAttachments],
      methods: {
         async fileChange(event) {

            this.loading = true;
            let files = event.target.files;

            if (this.media.length + files.length > 3) {
               this.$toast.error(this.$t("max_3_files"));
               this.loading = false;
               return;
            }


            /////// Toto kompresne fotky na frontende
            for (var i = 0; i < files.length; i ++) {
               let formData = new FormData;


               let convertedBlob = await new Promise((resolve) => {
                  new Compressor(files[i], {
                     quality: 0.6,
                     maxWidth: 800,

                     success(result) {

                        resolve(result);
                     },
                     error(err) {
                        console.log(err.message);
                        resolve(files[i]); // Ak konverzia zlyhá, použite pôvodný súbor
                     }
                  });
               });

               // Konverzia Blob na File
               let file = new File([convertedBlob], files[i].name, { type: files[i].type });

               formData.set("image", file);

               const { data } = await this.$axios.post(this.server, formData);

               this.media.push({ name: data.name, size: file.size, type: file.type, sectionId: this.sectionId });

            }

            this.loading = false;
            this.media_emit();
         },
         remove(index) {
            this.media.splice(index, 1);
            // this.media_emit();

            this.$emit("updatedMedia", this.sectionId, this.media);
         },
         media_emit() {
            this.$emit("media", this.media);
         }
      },
      mounted() {
         if (this.mediaFromLocalStorage) {
            this.media = this.mediaFromLocalStorage
         }
      },
      props: {
         // error:'',
         server: {
            type: String,
            default: "upload"
         },
         sectionId: {
            type: Number, // alebo String, záleží na type ID sekcie
            required: true
         },
         mediaFromLocalStorage: {}
      }
      // components:{Loader}
   };
</script>



