import axios from "axios";
import router from '../router'

export default {
    namespaced: true,
    state: {
        // contracts_all: [],
        orders_meta: [],
        orders: [],
        order: null
    },
    mutations: {
        SET_ORDERS(state, orders) {
            state.orders = orders.data
            state.orders_meta = orders.meta
        },
        // SET_CONTRACTS_ALL(state, contracts_all) {
        //     state.contracts_all = contracts_all
        // },
        SET_ORDER(state, order) {
            state.order = order
        },
        // ADD_CONTRACT(state, contract) {
        //     state.contracts.push(contract)
        // }
    },
    actions: {
        getOrders({commit},payload) {
            let params = {};
            if (payload !== null && payload !== undefined) {

            if (payload.page !== null && payload.page !== undefined ) {
                params['page'] = payload.page;
            }
            if (payload.per_page !== null && payload.per_page !== undefined) {
                params['per_page'] = payload.per_page;
            }
            if (payload.search_value !== null && payload.search_value !== undefined) {
                params['search_value'] = payload.search_value;
            }
            }
            axios.get('orders', {params: new URLSearchParams(params)})
                .then(response => {
                    commit('SET_ORDERS', response.data)
                })
        },
        // getContractsAll({ commit }) {
        //     axios.get(`contracts_all`)
        //         .then(response => {
        //             commit('SET_CONTRACTS_ALL', response.data)
        //         })
        // },
        getOrder({commit}) {
            axios.get('orders/' + router.currentRoute.params.order)
                .then(response => {
                    commit('SET_ORDER', response.data)
                })
        },
    }
}
