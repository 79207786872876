<template>
   <div class="layout-px-spacing" v-if="
        this.$can('admin') ||
        this.$can('office') ||
        this.$can('technik-manager') ||
        this.$can('obchodnik-manager') ||
        this.$can('rola-pobocka') ||
        this.$can('skladnik') ||
        this.$can('kurier') ||
        this.$can('infolinka') ||
        this.$can('technik')
   ">
      <breadcrumb />

      <div class="row layout-top-spacing">
         <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
            <div class="panel p-0">
               <div class="custom-table table3">

                  <div class="table-header">
                     <div class="header-buttons-text">
                        <h3>{{ $t('warehouse') }}</h3>
                        <div class="header-buttons">
                           <router-link :to="'/sklad/sam-prijemka/new'" class="mr-2 w-100">
                              <b-button variant="outline-success btn-sm">{{ $t('create_receipt_alone') }}</b-button>
                           </router-link>
                           <router-link :to="'/sklad/sam-vydajka/new'" class="mr-2 w-100">
                              <b-button variant="outline-warning btn-sm">{{ $t('create_issue_card_alone') }}</b-button>
                           </router-link>
                        </div>
                     </div>
                     <!--              <div class="d-flex align-users-center"><span>Results :</span><span class="ml-2"><select
                                       v-model="metaLocal.items.per_page"
                                       class="custom-select custom-select-sm"
                                       id="__BVID__320"><option
                                       value="5">5</option><option
                                       value="10">10</option><option value="20">20</option><option value="50">50</option></select></span>
                                   </div>-->
                     <div class="header-search">


                        <input type="text" v-model.trim="metaLocal.items.search_value"
                               :placeholder="$t('search')"
                               class="form-control form-control-sm"
                               id="__BVID__321">
                        <div class="search-image">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-search">
                              <circle cx="11" cy="11" r="8"></circle>
                              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                           </svg>
                        </div>
                     </div>
                  </div>

                  <div class="table-responsive">
                     <table class="table table-items table-hover">
                        <thead>
                           <tr>
                              <th>Id</th>
                              <th class="expanding-th">{{ $t('name') }} <span @click="expand = !expand"
                                    v-if="expand === true">▶</span><span @click="expand = !expand" v-else>◀</span></th>
                              <th>{{ $t('category') }}</th>
                              <th>MJ</th>
                              <th>{{ $t('count') }}</th>
                              <th>{{ $t('note') }}</th>
                           </tr>
                        </thead>
                        <tbody is="transition-group">
                           <tr v-if="items.length === 0" key="empty">
                              <td colspan="6" class="text-center">{{ $t('no_items') }}</td>
                           </tr>
                           <tr v-for="item in items" :key="item.id">
                              <td tabindex="0" class="">{{ item.id }}</td>
                              <td><span class="expanding-span" :class="{ 'expanding-span-toogle': expand }">{{ item.name
                                    }}</span></td>
                              <td>{{ item.category }}</td>
                              <td>{{ item.unit }}</td>
                              <td>{{ item.quantity }}</td>
                              <td>{{ item.note }}</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div class="table-footer">
                     <div v-if="items">{{ $t('it_is_displayed') }} {{ items.length }} {{ $t('of') }} {{ items_meta.total
                        }}</div>
                     <div class="paginating-container pagination-solid flex-column align-users-right">
                        <b-pagination v-if="items" prev-text="Prev" next-text="Next" first-text="First" last-text="Last"
                           first-class="first" prev-class="prev" next-class="next" last-class="last" class="rounded"
                           v-model="items_meta.current_page" :total-rows="items_meta.total"
                           :per-page="items_meta.per_page" @change="handleItemsPageChange">
                           <template #first-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #prev-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M15 19l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #next-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M9 5l7 7-7 7" />
                              </svg>
                           </template>
                           <template #last-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                              </svg>
                           </template>
                        </b-pagination>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
            <div class="panel p-0">
               <div class="custom-table table3">
                  <div class="table-header">
                     <h3>{{ $t('my_transfers') }}</h3>
                     <!--              <div class="d-flex align-users-center"><span>Results :</span><span class="ml-2"><select
                                       v-model="metaLocal.movements.per_page"
                                       class="custom-select custom-select-sm"
                                       id="__BVID__320"><option
                                       value="5">5</option><option
                                       value="10">10</option><option value="20">20</option><option value="50">50</option></select></span>
                                   </div>-->
                     <div class="">
                        <div class="header-search">
                           <div class="header-search">
                              <input type="text" v-model.trim="metaLocal.movements.search_value"
                                 :placeholder="$t('search')" class="form-control form-control-sm" id="__BVID__321">
                              <div class="search-image">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-search">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                 </svg>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="table-responsive">
                     <table class="table table-hover">
                        <thead>
                           <tr>
                              <th>Id</th>
                              <th>{{ $t('type') }}</th>
                              <th>{{ $t('order') }}</th>
                              <th>{{ $t('supplier') }}</th>
                              <th>{{ $t('recipient') }}</th>
                              <th>{{ $t('date') }}</th>
                              <th>{{ $t('note') }}</th>
                              <th>{{ $t('items') }}</th>
                           </tr>
                        </thead>
                        <tbody is="transition-group">
                           <tr v-if="movements.length === 0" key="empty">
                              <td colspan="8" class="text-center">{{ $t('no_items') }}</td>
                           </tr>
                           <tr v-for="item in movements" :key="item.id">
                              <td tabindex="0" class="">{{ item.id }}</td>
                              <td>
                                 <b-badge
                                    :variant="item.type === 'IN' || item.type === 'SELF_IN' ? 'success' : 'warning'">
                                    {{ item.type_value }}
                                 </b-badge>
                              </td>
                              <td>
                                 <router-link v-if="item.order != null" :to="'/objednavky/' + item.order.id + '/detail'"
                                    class="badge inv-status badge-dark" v-b-tooltip.hover :title="$t('detail')">{{
                                       item.order.identifier + (item.order.name != null ?" - " + item.order.name : "") }}
                                 </router-link>
                                 <span v-else>
                                    -
                                 </span>
                              </td>
                              <td>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-user placeholder">
                                    <path data-v-17470ab0="" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle data-v-17470ab0="" cx="12" cy="7" r="4"></circle>
                                 </svg>
                                 {{ item.from_user != null ? item.from_user.name : item.other_user_name }}
                              </td>
                              <td>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-user placeholder">
                                    <path data-v-17470ab0="" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle data-v-17470ab0="" cx="12" cy="7" r="4"></circle>
                                 </svg>
                                 {{ item.to_user != null ? item.to_user.name : item.other_user_name }}
                              </td>
                              <td>{{ item.created_at }}</td>
                              <td>{{ item.note != null && item.note !== "" ? item.note : "-" }}</td>
                              <td>
                                 <b-button @click="openItemsModal(item)" variant="outline-primary btn-sm">{{ $t('items')
                                    }}</b-button>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <!--                          <div class="loading-row" v-if="loading">-->
                     <!--                          </div>-->
                  </div>
                  <div class="table-footer">
                     <div v-if="movements">{{ $t('it_is_displayed') }} {{ movements.length }} {{ $t('of') }} {{
                        movements_meta.total }}</div>
                     <div class="paginating-container pagination-solid flex-column align-users-right">
                        <b-pagination v-if="movements" prev-text="Prev" next-text="Next" first-text="First"
                           last-text="Last" first-class="first" prev-class="prev" next-class="next" last-class="last"
                           class="rounded" v-model="movements_meta.current_page" :total-rows="movements_meta.total"
                           :per-page="movements_meta.per_page" @change="handleMovementsPageChange">
                           <template #first-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #prev-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M15 19l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #next-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M9 5l7 7-7 7" />
                              </svg>
                           </template>
                           <template #last-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                              </svg>
                           </template>
                        </b-pagination>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>

      <!-- Modal ADD-->
      <b-modal centered id="addSelfReceiveOrderModal" :static="true" :title="$t('create_new_reciep')" size="md">
         <div class="add-contact-box">
            <div class="add-contact-content">
               <b-form id="addItemModalTitle">
                  <div class="row">
                     <div class="col-md-12">
                        <div class="row">
                           <div class="col-12">
                              <div class="form-group">
                                 <b-input :state="validateState('item_name')" v-model="$v.form.item_name.$model"
                                    :placeholder="$t('sender')"></b-input>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <b-input :state="validateState('item_name')" v-model="$v.form.item_name.$model"
                                    :placeholder="$t('recipient')"></b-input>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <b-select :state="validateState('item_category')"
                                    v-model="$v.form.item_category.$model" :label="$t('category')">
                                    <b-select-option :value="null">{{ $t('category') }}</b-select-option>
                                    <b-select-option v-for="category in categories" :value="category.id"
                                       :key="category.id">
                                       {{ category.name }}
                                    </b-select-option>
                                 </b-select>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <b-select :state="validateState('item_unit')" v-model="$v.form.item_unit.$model">
                                    <b-select-option :value="null">MJ</b-select-option>
                                    <b-select-option v-for="unit in units" :value="unit.id" :key="unit.id">
                                       {{ unit.name }}
                                    </b-select-option>
                                 </b-select>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="form-group">
                                 <b-input :state="validateState('item_note')" v-model="$v.form.item_note.$model"
                                    :placeholder="$t('note')"></b-input>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </b-form>
            </div>
         </div>
      </b-modal>

      <!-- Modal EDIT-->
      <b-modal centered id="movementItemsModal" :static="true" :title="$t('transfer_items')" size="md">
         <div v-if="actualMovement != null" class="table-responsive custom-table">
            <table class="table table-hover">
               <thead>
                  <tr>
                     <th>Id</th>
                     <th>{{ $t('name') }}</th>
                     <!--              <th>MJ</th>-->
                     <th>{{ $t('count') }}</th>
                     <th>{{ $t('note') }}</th>
                  </tr>
               </thead>
               <tbody is="transition-group">
                  <tr v-if="actualMovement.items.length === 0" key="empty">
                     <td colspan="6" class="text-center">{{ $t('no_items') }}</td>
                  </tr>
                  <tr v-for="item in actualMovement.items" :key="item.id">
                     <td tabindex="0" class="">{{ item.id }}</td>
                     <td>{{ item.name }}</td>
                     <!--              <td>{{ item.unit }}</td>-->
                     <td>{{ item.quantity }}</td>
                     <td v-if="item.note === 'undefined'">-</td>
                     <td v-else-if="item.note === ''">-</td>
                     <td v-else>{{ item.note }}</td>

                  </tr>
               </tbody>
            </table>
            <!--                          <div class="loading-row" v-if="loading">-->
            <!--                          </div>-->
         </div>

         <template #modal-footer>
            <b-button variant="cancel" data-dismiss="modal" @click="cancel_item">{{ $t('cancel') }}</b-button>
         </template>
      </b-modal>
   </div>
   <div class="layout-px-spacing d-flex align-items-center justify-content-center" v-else>
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center px-3">{{ $t('not_allowed') }}</h4>
         </div>
      </div>
   </div>
</template>

<script>
import "../../assets/sass/global/table.scss";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import _debounce from "lodash/debounce";

export default {
   mixins: [validationMixin],
   validations: {
      form: {
         item_name: {
            required
         },
         item_category: {
            required
         },
         item_unit: {
            required
         },
         item_note: {}
      }
   },
   data() {
      return {
         metaLocal: {
            items: {
               per_page: 10,
               search_value: "",
               page: 1
            },
            movements: {
               per_page: 10,
               search_value: "",
               page: 1
            }
         },
         options: [
            { value: "5", text: "5" },
            { value: "10", text: "10" },
            { value: "20", text: "20" },
            { value: "50", text: "50" }
         ],
         form: {
            item_id: "",
            item_name: "",
            item_category: null,
            item_unit: null,
            item_note: ""
         },
         categories: {},
         units: {},
         actualMovement: null,
         expand: false
      };
   },
   watch: {
      "metaLocal.items.search_value": {
         handler: _debounce(function () {
            this.metaLocal.items.page = 1;
            this.fetchItemsData();
         }, 250)
      },
      "metaLocal.movements.search_value": {
         handler: _debounce(function () {
            this.metaLocal.movements.page = 1;
            this.fetchMovementData();
         }, 250)
      },
      "metaLocal.items.per_page": {
         handler: _debounce(function () {
            if (this.metaLocal.items.per_page > this.items_meta.total) {
               this.metaLocal.items.page = 1;
            }
            this.fetchItemsData();
         }, 250)
      },
      "metaLocal.movements.per_page": {
         handler: _debounce(function () {
            if (this.metaLocal.movements.per_page > this.items_meta.total) {
               this.metaLocal.movements.page = 1;
            }
            this.fetchMovementData();
         }, 250)
      }
   },
   created() {
      // Retrieve the current locale from the route parameters
      const locale = this.$route.params.locale;

      // Fetch items and movement data, passing the locale if needed
      this.fetchItemsData(locale);
      this.fetchMovementData(locale);

      // Fetch item categories with locale
      this.$axios.get('item_categories')
         .then(response => {
            this.categories = response.data;
         })
         .catch(error => {
            console.error('Error fetching categories:', error);
         });

      // Fetch units with locale
      this.$axios.get('units')
         .then(response => {
            this.units = response.data;
         })
         .catch(error => {
            console.error('Error fetching units:', error);
         });
   },

   computed: {
      items() {
         return this.$store.state.storage.items;
      },
      items_meta() {
         return this.$store.state.storage.items_meta;
      },
      movements() {
         return this.$store.state.storage.movements;
      },
      movements_meta() {
         return this.$store.state.storage.movements_meta;
      },
      ...mapState("loader", ["loading"])
   },
   methods: {
      fetchItemsData() {
         this.$store.dispatch("storage/getItems", {
            page: this.metaLocal.items.page,
            per_page: this.metaLocal.items.per_page,
            search_value: this.metaLocal.items.search_value
         });
      }, fetchMovementData() {
         this.$store.dispatch("storage/getMovements", {
            page: this.metaLocal.movements.page,
            per_page: this.metaLocal.movements.per_page,
            search_value: this.metaLocal.movements.search_value
         });
      },
      handleItemsPageChange(value) {
         this.metaLocal.items.page = value;
         this.fetchItemsData();
      },
      handleMovementsPageChange(value) {
         this.metaLocal.movements.page = value;
         this.fetchMovementData();
      },
      validateState(name) {
         const { $dirty, $error } = this.$v.form[name];
         return $dirty ? !$error : null;
      },
      openItemsModal(movement) {
         this.actualMovement = movement;
         this.$bvModal.show("movementItemsModal");
      },
      // add_self_receive_order_modal() {
      //   this.form.item_id = "";
      //   this.form.item_name = "";
      //   this.form.item_category = null;
      //   this.form.item_unit = null;
      //   this.form.item_note = "";
      //   this.$v.$reset();
      //   this.$bvModal.show("addSelfReceiveOrderModal");
      // },
      // add_self_send_order_modal() {
      //   this.form.item_id = "";
      //   this.form.item_name = "";
      //   this.form.item_category = null;
      //   this.form.item_unit = null;
      //   this.form.item_note = "";
      //   this.$v.$reset();
      //   this.$bvModal.show("addSelfSendOrderModal");
      // },
      //        edit_item_modal(id) {
      //         /* this.$axios.get("items/"+id)
      //              .then((response) => {
      //                this.form.item_id = response.data.id;
      //                this.form.item_name = response.data.name;
      //                this.form.item_category = response.data.item_category_id;
      //                this.form.item_unit = response.data.unit_id;
      //                this.form.item_note = response.data.note;
      //              });
      //          */
      //
      //          const itemData = this.items.find(x => x.id === id);
      // //'from_user_id' => 'nullable|exists:users,id',
      //          // 'to_user_id' => 'nullable|exists:users,id',
      //          //     'other_user_name' => 'nullable|string',
      //          //     'order_id' => 'nullable|exists:orders,id',
      //          //     'created_by' => 'required|exists:users,id',
      //          //     'note' => 'nullable',
      //          //     'type' => 'required|in:IN,OUT,SELF_IN,SELF_OUT',
      //          //     'items' => 'required|array',
      //          //     'items.*.id' => 'required|exists:items,id',
      //          //     'items.*.quantity' => 'required|numeric',];
      //          this.form.item_id = itemData.id;
      //          this.form.item_name = itemData.name;
      //          this.form.item_category = itemData.category.id;
      //          this.form.item_unit = itemData.unit.id;
      //          this.form.item_note = itemData.note;
      //          this.$bvModal.show("editItemModal");

      // },
      cancel_item() {
         this.$bvModal.hide("movementItemsModal");
         // this.$bvModal.hide("addSelfReceiveOrderModal");
         // this.$bvModal.hide("editItemModal");
      },
      add_self_send_order() {
         this.$v.form.$touch();
         if (this.$v.form.$anyError) {
            this.$toast.error(this.$t("missing_credentials"));
            return;
         }

         let data = {
            name: this.form.item_name,
            item_category_id: this.form.item_category,
            unit_id: this.form.item_unit,
            note: this.form.item_note
         };

         this.$store.dispatch("loader/pending");
         this.$axios.post("storage", data)
            .then((response) => {
               this.$bvModal.hide("add_self_send_order_modal");
               this.$store.dispatch("storage/getItems");
               this.$toast.success(response.data.message);
               this.$store.dispatch("loader/done");
            })
            .catch(response => {
               this.$toast.error(response.response.data.message);
               this.$store.dispatch("loader/done");
            });
      },
      // add_self_receive_order() {
      //   this.$v.form.$touch();
      //   if (this.$v.form.$anyError) {
      //     this.$toast.error(this.$t("missing_credentials"));
      //     return;
      //   }
      //
      //   //'from_user_id' => 'nullable|exists:users,id',
      //   // 'to_user_id' => 'nullable|exists:users,id',
      //   //     'other_user_name' => 'nullable|string',
      //   //     'order_id' => 'nullable|exists:orders,id',
      //   //     'created_by' => 'required|exists:users,id',
      //   //     'note' => 'nullable',
      //   //     'type' => 'required|in:IN,OUT,SELF_IN,SELF_OUT',
      //   //     'items' => 'required|array',
      //   //     'items.*.id' => 'required|exists:items,id',
      //   //     'items.*.quantity' => 'required|numeric',];
      //   let data = {
      //     name: this.form.item_name,
      //     item_category_id: this.form.item_category,
      //     unit_id: this.form.item_unit,
      //     note: this.form.item_note,
      //   };
      //
      //   this.$store.dispatch("loader/pending");
      //   this.$axios.post("storage", data)
      //       .then((response) => {
      //         this.$bvModal.hide("add_self_receive_order_modal");
      //         this.$store.dispatch("storage/getItems");
      //         this.$toast.success(response.data.message);
      //         this.$store.dispatch("loader/done");
      //       })
      //       .catch(response => {
      //         this.$toast.error(response.response.data.message);
      //         this.$store.dispatch("loader/done");
      //       });
      // },
      //        edit_item() {
      //          this.$v.form.$touch();
      //          if (this.$v.form.$anyError) {
      //            this.$toast.error(this.$t("missing_credentials"));
      //            return;
      //          }
      // //'from_user_id' => 'nullable|exists:users,id',
      //          // 'to_user_id' => 'nullable|exists:users,id',
      //          //     'other_user_name' => 'nullable|string',
      //          //     'order_id' => 'nullable|exists:orders,id',
      //          //     'created_by' => 'required|exists:users,id',
      //          //     'note' => 'nullable',
      //          //     'type' => 'required|in:IN,OUT,SELF_IN,SELF_OUT',
      //          //     'items' => 'required|array',
      //          //     'items.*.id' => 'required|exists:items,id',
      //          //     'items.*.quantity' => 'required|numeric',];
      //          let data = {
      //            item_id: this.form.item_id,
      //            name: this.form.item_name,
      //            item_category_id: this.form.item_category,
      //            unit_id: this.form.item_unit,
      //            note: this.form.item_note,
      //          };
      //
      //          this.$axios.put(`items/${this.form.item_id}`, data)
      //              .then((response) => {
      //                console.log(response)
      //                if (response !== undefined) {
      //                  this.$bvModal.hide("editItemModal");
      //                  this.$store.dispatch("item/getItems");
      //                  this.$toast.success(response.data.message);
      //                } else {
      //                  console.log('zhoda')
      //                  this.$toast.error(this.$t("match_find")); 
      //                }
      //              });
      //        },
      delete_item(id) {
         if (window.confirm("Opravdu to chceš vymazat?")) {
            this.$axios.delete(`items/${id}`)
               .then((response) => {
                  console.log("then");
                  console.log(response);
                  this.$store.dispatch("item/getItems");
                  this.$toast.success(response.data.message);
               }).catch(error => {
                  console.log("catch");
                  this.$toast.error(error.data.error);
               });
         }
      }
   }
};
</script>

<!--<style lang="scss">-->
<!--  @-webkit-keyframes moving-gradient {-->
<!--    0% { background-position: -250px 0; }-->
<!--    100% { background-position: 250px 0; }-->
<!--  }-->

<!--  .loading-row {-->

<!--      width: 100%;-->
<!--      height: 50px;-->
<!--      background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);-->
<!--      background-size: 500px 100px;-->
<!--      animation-name: moving-gradient;-->
<!--      animation-duration: 1s;-->
<!--      animation-iteration-count: infinite;-->
<!--      animation-timing-function: linear;-->
<!--      animation-fill-mode: forwards;-->

<!--  }-->
<!--</style>-->

<style scoped>
.table {
   white-space: nowrap;
}

.active-status {
   content: "";
   position: absolute;
   bottom: 7%;
   right: -4%;
   width: 30%;
   height: 30%;
   border-radius: 50%;
   border: 2px solid #fff;
   background-color: #1abc9c;
}

.usr-img-frame {
   position: relative;
}


.table-header h3 {
   margin-bottom: 0.6rem;

   @media (max-width: 576px) {
      margin-bottom: 1.2rem;
   }
}

.header-buttons-text {
   display: flex;
   align-items: center;

   @media (max-width: 767px) {
      flex-direction: column;
   }

   @media (max-width: 576px) {
      width: 100%;
      text-align: center;
      justify-content: center;
   }

   .header-buttons {
      margin-left: 1rem;

      @media (max-width: 576px) {
         display: flex;
         margin-left: 0;
         width: 100%;
      }
   }
}
</style>
