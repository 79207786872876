<template>
    <div>
<!--        <portal to="back-button">-->
<!--            <div  @click="back">Back</div>-->
<!--        </portal>-->
        <portal to="breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb" v-if="!params">
                                <li class="breadcrumb-item active"><a href="javascript:;">{{ this.$route.meta }}</a></li>
                            </ol>
                            <ol class="breadcrumb" v-if="params && !isBussy">
                                <li class="breadcrumb-item"><a href="javascript:;">{{ this.$route.meta }}</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>{{ params }}</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </portal>
    </div>

</template>

<script>
    export default {
        props: [
            'params'
        ],
        data() {
            return {
                isBussy: true
            }
        },
        // methods: {
        //   back() {
        //       this.$router.go(-1)
        //   }
        // },
        mounted() {
            setTimeout(() => {
                this.isBussy = false
            }, 100)
        }
    }
</script>
