import axios from "axios";

export default {
   namespaced: true,
   state: {
      items: [],
      items_meta: []
   },
   mutations: {
      SET_ITEMS(state, items) {
         state.items = items.data;
         state.items_meta = items.meta;
      }
   },
   actions: {
      getItems({ commit }, payload) {
         let params = {};
         if (payload !== null && payload !== undefined) {

            if (payload.page !== null && payload.page !== undefined) {
               params["page"] = payload.page;
            }
            if (payload.per_page !== null && payload.per_page !== undefined) {
               params["per_page"] = payload.per_page;
            }
            if (payload.search_value !== null && payload.search_value !== undefined) {
               params["search_value"] = payload.search_value;
            }
            if (payload.all !== null && payload.all !== undefined) {
               params["all"] = payload.all;
            }
         }
         axios.get(`items`, { params: new URLSearchParams(params) })
           .then(response => {
              commit("SET_ITEMS", response.data);
           });
      }
   }
};
