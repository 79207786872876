export const loader = {
   namespaced: true,
   state: {
      loading: false,
      requestsPending: 0,
      uploadPercentage: null
   },
   actions: {
      show({ commit }) {
         commit("show");
      },
      hide({ commit }) {
         commit("hide");
      },
      pending({ commit }) {
         commit("pending");
      },
      done({ commit }) {
         commit("done");
      },
      setUploadPercentage({ commit }, percentage) {
         commit("setUploadPercentage", percentage);
      }
   },
   mutations: {
      show(state) {
         state.loading = true;
      },
      hide(state) {
         state.loading = false;
      },
      pending(state) {
         if (state.requestsPending === 0) {
            this.commit("loader/show");
         }

         state.requestsPending ++;
      },
      done(state) {
         if (state.requestsPending >= 1) {
            state.requestsPending --;
         }

         if (state.requestsPending <= 0) {
            this.commit("loader/hide");
         }
      },
      setUploadPercentage(state, percentage) {
         state.uploadPercentage = percentage;
      }
   }
};
