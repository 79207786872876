<template>
   <div class="layout-px-spacing">
      <breadcrumb />

      <div class="row layout-top-spacing">
         <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
            <div class="panel p-0">
               <div class="custom-table table3">
                  <div class="table-header">
                     <h3>{{ $t('list_of_user_entries') }}</h3>

                     <div class="header-search">
                        <router-link to="/rezervacie/new" v-b-tooltip.hover :title="$t('detail')">

                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-plus-circle">
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                           </svg>
                        </router-link>
                        <input type="text" v-model.trim="search_value" :placeholder="$t('search')"
                           class="form-control form-control-sm" id="__BVID__321">
                        <div class="search-image">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-search">
                              <circle cx="11" cy="11" r="8"></circle>
                              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                           </svg>
                        </div>
                     </div>
                  </div>
                  <div class="table-responsive">
                     <table class="table table-items table-hover" ref="table">
                        <!--                <template>-->
                        <thead>
                           <tr>
                              <th>Id</th>
                              <th class="expanding-th">{{ $t('name') }} <span @click="expand = !expand"
                                    v-if="expand === true">▶</span><span @click="expand = !expand" v-else>◀</span></th>
                              <th>{{ $t('category') }}</th>
                              <th>MJ</th>
                              <th v-for="user in users" :key="user.id">
                                 {{ user.name }}
                              </th>
                           </tr>
                        </thead>
                        <tbody name="users_ta" is="transition-group">
                           <!--                <tr v-if="items.length === 0" key="empty">-->
                           <!--                  <td colspan="6" class="text-center">{{ $t('no_items') }}</td>-->
                           <!--                </tr>-->
                           <!--                <tr v-if="loading" key="empty">-->
                           <!--                  <td colspan="6" class="text-center">Načítavam</td>-->
                           <!--                </tr>-->
                           <tr v-for="item in items" :key="item.id ?? 'new'">

                              <td>{{ item.id }}</td>
                              <td><span class="expanding-span" :class="{ 'expanding-span-toogle': expand }">{{ item.name
                                    }}</span></td>
                              <td>{{ item.category.name }}</td>
                              <td>{{ item.unit.name }}</td>
                              <td v-for="user in users" :key="'user' + user.id">
                                 <span v-if="item.users?.find(u => u.id === user.id)" class="mr-2">
                                    <span class="badge badge-success"
                                       v-if="item.users?.find(u => u.id === user.id).pivot.quantity >= 0">{{
                                          item.users?.find(u => u.id === user.id).pivot.quantity
                                       }}</span>
                                    <span class="badge badge-danger" v-else>{{
                                       item.users?.find(u => u.id === user.id).pivot.quantity
                                    }}</span>

                                 </span>
                                 <span class="mr-2" v-else>
                                    0
                                 </span>
                                 <span @click="showReservationsModal(item, user.id)"
                                    v-if="item.reservedCount[user.id] > 0" class="badge badge-warning cursor-pointer"
                                    v-b-tooltip.hover :title="$t('reservations')">{{
                                       item.reservedCount[user.id]
                                    }}</span>
                              </td>

                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div class="table-footer">
                     <div v-if="items">{{ $t('it_is_displayed') }} {{ items.length }} {{ $t('of') }} {{ meta?.total }}
                     </div>
                     <div class="paginating-container pagination-solid flex-column align-items-right">
                        <b-pagination v-if="items" prev-text="Prev" next-text="Next" first-text="First" last-text="Last"
                           first-class="first" prev-class="prev" next-class="next" last-class="last" class="rounded"
                           v-model="meta.current_page" :total-rows="meta?.total" :per-page="meta?.per_page"
                           @change="handlePageChange">
                           <template #first-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #prev-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M15 19l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #next-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M9 5l7 7-7 7" />
                              </svg>
                           </template>
                           <template #last-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                              </svg>
                           </template>
                        </b-pagination>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <b-modal centered id="reservationsModal" :static="true" :title="$t('reservation_items')" size="xl">
         <div class="table-responsive custom-table">
            <table class="table table-hover">
               <thead>
                  <tr>
                     <th>Id</th>
                     <th>{{ $t('id_number') }}</th>
                     <th>{{ $t('client_name') }}</th>
                     <th>{{ $t('created_by') }}</th>
                     <th>{{ $t('recipient') }}</th>
                     <th>{{ $t('date_of_realisation') }}</th>
                     <th>{{ $t('items') }}</th>
                     <th>{{ $t('options') }}</th>
                  </tr>
               </thead>
               <tbody is="transition-group">
                  <tr v-if="loadingReservations" key="empty">
                     <td colspan="7" class="text-center">{{ $t('loading') }}</td>
                  </tr>
                  <tr v-else-if="reservations.length === 0" key="empty">
                     <td colspan="7" class="text-center">{{ $t('no_reservations') }}</td>
                  </tr>
                  <tr v-for="item in reservations" :key="item.id">
                     <td tabindex="0" class="">{{ item.id }}</td>
                     <td>{{ item.op_number }}</td>
                     <td>{{ item.client_name }}</td>
                     <td>{{ item.from_user.name }}</td>
                     <td>{{ item.to_user.name }}</td>
                     <td>{{ item.realisation_date }}</td>
                     <td>
                        <b-button @click="showReservationItemsModal(item)" variant="outline-primary btn-sm">{{
                           $t('items')
                           }}</b-button>
                     </td>
                     <td><a href="javascript:;" @click="removeReservation(item)" v-b-tooltip.hover
                           :title="$t('delete')">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-trash-2">
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                              </path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                           </svg>
                        </a></td>
                  </tr>
               </tbody>
            </table>
            <!--                          <div class="loading-row" v-if="loading">-->
            <!--                          </div>-->
         </div>

         <template #modal-footer>
            <b-button variant="cancel" data-dismiss="modal" @click="cancelReservationsModal">{{ $t('cancel')
               }}</b-button>
         </template>
      </b-modal>
      <b-modal centered id="reservationItemsModal" :static="true" :title="$t('reservation_items')" size="md">
         <div v-if="actualReservation != null" class="table-responsive custom-table">
            <table class="table table-hover">
               <thead>
                  <tr>
                     <th>Id</th>
                     <th>{{ $t('name') }}</th>
                     <!--              <th>MJ</th>-->
                     <th>{{ $t('count') }}</th>
                     <th>{{ $t('note') }}</th>
                  </tr>
               </thead>
               <tbody is="transition-group">
                  <tr v-if="actualReservation.items.length === 0" key="empty">
                     <td colspan="6" class="text-center">{{ $t('no_items') }}</td>
                  </tr>
                  <tr v-for="item in actualReservation.items" :key="item.id">
                     <td tabindex="0" class="">{{ item.id }}</td>
                     <td>{{ item.name }}</td>
                     <!--              <td>{{ item.unit }}</td>-->
                     <td>{{ item.pivot.quantity }}</td>
                     <td>{{ item.pivot.note ?? "-" }}</td>

                  </tr>
               </tbody>
            </table>
            <!--                          <div class="loading-row" v-if="loading">-->
            <!--                          </div>-->
         </div>

         <template #modal-footer>
            <b-button variant="cancel" data-dismiss="modal" @click="hideReservationItems">{{ $t('cancel') }}</b-button>
         </template>
      </b-modal>
   </div>
</template>

<script>
import "../../assets/sass/global/table.scss";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import _debounce from "lodash/debounce";

export default {
   mixins: [validationMixin],
   validations: {
      form: {
         item_name: {
            required
         },
         item_category: {
            required
         },
         item_unit: {
            required
         },
         item_note: {}
      }

   },
   data() {
      return {
         actualReservation: null,
         per_page: 100,
         search_value: "",
         page: 1,
         options: [
            { value: "5", text: "5" },
            { value: "10", text: "10" },
            { value: "20", text: "20" },
            { value: "50", text: "50" }
         ],
         loadingReservations: false,
         form: {
            item_id: "",
            item_name: "",
            item_category: null,
            item_unit: null,
            item_note: ""
         },
         //
         categories: {},
         units: {},
         //<th>Id</th>
         //   <th>{{ $t('name') }}</th>
         //   <th>{{ $t('category') }}</th>
         //   <th>MJ</th>
         //   <th v-for="user in users" :key="user">
         //       {{ user.name }}
         // </th>
         fields: [
            { key: "id", stickyColumn: true, isRowHeader: true, variant: "primary" },
            "Názov",
            "Kategória",
            "MJ"
         ],
         expand: false
      };
   },
   created() {
      // Fetch the current locale from the route parameters
      const locale = this.$route.params.locale;

      // Dispatch actions and fetch data with the locale
      this.fetchData(locale);
      this.$store.dispatch("user/getAllUsers");

      // Fetch categories based on locale
      this.$axios.get('item_categories')
         .then(response => {
            this.categories = response.data;
         })
         .catch(error => {
            console.error('Error fetching categories:', error);
         });

      // Fetch units based on locale
      this.$axios.get('units')
         .then(response => {
            this.units = response.data;
         })
         .catch(error => {
            console.error('Error fetching units:', error);
         });
   },

   computed: {
      meta() {
         return this.$store.state.item.items_meta;
      },
      items() {
         return this.$store.state.item.items;
      },
      reservations() {
         return this.$store.state.reservation.reservations;
      },
      users() {
         return this.$store.state.user.users.filter(user => user.role?.name !== "obchodnik" && user.role?.name !== "obchodnik-manager" && user.role?.name !== "infolinka");
      },
      ...mapState("loader", ["loading"])
   },
   watch: {
      search_value: {
         handler: _debounce(function () {
            this.fetchData();
         }, 250),
         immediate: false
      },
      per_page() {
         if (this.per_page > this.meta.total) {
            this.page = 1;
         }
         this.fetchData();
      }
      // users: function () {
      // this.users.forEach((user) => {
      //   this.fields.push({
      //     key: user.name
      //   })
      //   //<span v-if="item.users.find(u => u.id === user.id)">
      //   //   {{ item.users.find(u => u.id === user.id).pivot.quantity }}
      //   // </span>
      //   //   <span v-else>
      //   //                 -
      //   //               </span>
      // })
      // }
   },
   methods: {
      removeReservation(item) {
         if (window.confirm("Opravdu to chceš vymazat?")) {
            let itemId = this.$store.state.reservation.itemId;
            this.$axios.delete(`reservations/${item.id}`)
               .then((response) => {
                  this.$toast.success(response.data.message);
                  if (this.$store.state.reservation.reservations.length == 1) {
                     this.cancelReservationsModal();
                  }

                  this.$store.dispatch("reservation/getItemReservations", {
                     item_id: itemId,
                     user_id: item.to_user.id
                  });
                  this.$store.dispatch("item/getItems");

               }).catch(error => {
                  this.$toast.error(error.response.statusText);
               });
         }
      },
      cancelReservationsModal() {
         this.$bvModal.hide("reservationsModal");
      },
      hideReservationItems() {
         this.$bvModal.hide("reservationItemsModal");
      },
      handlePageChange(value) {
         this.page = value;
         this.fetchData();
      },
      showReservationsModal(item, userId) {
         this.loadingReservations = true;

         this.$store.dispatch("reservation/getItemReservations", {
            item_id: item.id,
            user_id: userId
         }).then(() => {
            this.loadingReservations = false;
         });

         this.$bvModal.show("reservationsModal");
      },
      showReservationItemsModal(item) {
         this.actualReservation = item;
         this.$bvModal.show("reservationItemsModal");
      },
      fetchData() {
         this.$store.dispatch("item/getItems", {
            page: this.page,
            per_page: this.per_page,
            search_value: this.search_value
         });
      },
      validateState(name) {
         const { $dirty, $error } = this.$v.form[name];
         return $dirty ? !$error : null;
      }
   }
};
</script>

<style scoped>
.table {
   white-space: nowrap;
}

.active-status {
   content: "";
   position: absolute;
   bottom: 7%;
   right: -4%;
   width: 30%;
   height: 30%;
   border-radius: 50%;
   border: 2px solid #fff;
   background-color: #1abc9c;
}

.usr-img-frame {
   position: relative;
}


.table-header h3 {
   margin-bottom: 0.6rem;

   @media (max-width: 576px) {
      margin-bottom: 1.2rem;
   }
}
</style>
