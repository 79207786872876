import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//import axios
import axios from "axios";

//vue-i18n
import i18n from "./i18n";

Vue.config.productionTip = false;
window.i18n = i18n;

axios.defaults.baseURL = process.env.VUE_APP_API_URL_API;
Vue.prototype.$axios = axios;
axios.defaults.showLoader = true;

axios.interceptors.response.use(
  response => response,
  error => {
     if (error.response.status === 422) {
        store.commit("setErrors", error.response.data.errors);
        return Promise.reject(error);
     } else if (error.response.status === 401) {
        Vue.$toast.error(i18n.t("unauthorized"));
        store.commit("auth/setUserData", null);
        localStorage.removeItem("authToken");
        window.location.reload();
     } else {
        return Promise.reject(error);
     }
  }
);

axios.interceptors.request.use(function(config) {
   config.headers.common = {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "Content-Type": "application/json",
      Accept: "application/json",

   };

   return config;
});

//bootstrap vue
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);


//perfect scrollbar
import PerfectScrollbar from "vue2-perfect-scrollbar";

Vue.use(PerfectScrollbar);


import VeeValidate from "vee-validate";

Vue.use(VeeValidate, {
   inject: true,
   fieldsBagName: "veeFields",
   errorBagName: "veeErrors"
});

//vue-meta
import VueMeta from "vue-meta";

Vue.use(VueMeta, {
   refreshOnceOnNavigation: true
});

//moments.js
import moment from "moment";

Vue.prototype.$moment = moment;

//Pusher live
/*import Echo from "laravel-echo";

window.Pusher = require("pusher-js");
window.Echo = new Echo({
   authEndpoint: process.env.VUE_APP_API_URL + "broadcasting/auth",
   broadcaster: "pusher",
   key: process.env.VUE_APP_PUSHER_APP_KEY,
   cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
   forceTLS: true,
   auth: {
      headers: {
         Authorization: `Bearer ${localStorage.getItem("authToken")}`
      }
   }
});*/


//Permission
import Permissions from "./mixins/Permissions";

Vue.mixin(Permissions);


//portal vue
import PortalVue from "portal-vue";

Vue.use(PortalVue);


// set default settings
import appSetting from "./app-setting";

import "./registerServiceWorker";

Vue.prototype.$appSetting = appSetting;
appSetting.init();

// Notifications
import Toast from "vue-toastification";

const optionsToast = {
   position: "top-right",
   timeout: 2500,
   closeOnClick: true,
   pauseOnFocusLoss: false,
   pauseOnHover: false,
   draggable: true,
   draggablePercent: 0.6,
   showCloseButtonOnHover: false,
   hideProgressBar: false,
   icon: true,
   rtl: false
};
Vue.use(Toast, optionsToast);


//Lightbox
import VueEasyLightbox from "vue-easy-lightbox";

Vue.use(VueEasyLightbox);

import breadcrumb from "./components/breadcrumb";

Vue.component("breadcrumb", breadcrumb);

new Vue({
   created() {
      axios.interceptors.request.use(
        config => {
           if (config.showLoader) {
              store.dispatch("loader/pending");
           }
           config.onUploadProgress = progressEvent => {
              const uploadPercentage = Math.round(
                (progressEvent.loaded / progressEvent.total)* 100
              );
              store.dispatch("loader/setUploadPercentage", uploadPercentage);
           };
           return config;
        },
        error => {
           if (error.config.showLoader) {
              store.dispatch("loader/done");
           }
           return Promise.reject(error);
        }
      );
      axios.interceptors.response.use(
        response => {
           if (response.config.showLoader) {
              store.dispatch("loader/done");
           }
           store.state.loader.uploadPercentage = null;

           return response;
        },
        error => {
           let response = error.response;

           if (response) {
              if (response.status === 400) {
                 store.dispatch("loader/done");
                 this.$toast.error(response.data.error);
              }
              if (response.status === 403) {
                 store.dispatch("loader/done");
                 if (response.data.error) {
                    this.$toast.error(response.data.error);
                 }
              }
              if (response.status === 404) {
                 store.dispatch("loader/done");
                 this.$router.push({ name: "404" });
              }
              if (response.status === 500) {
                 store.dispatch("loader/done");
                 this.$router.push({ name: "500" });
              }
           }

           return Promise.reject(error);
        }
      );
   },
   router,
   store,
   i18n,
   render: h => h(App)
}).$mount("#app");
