<template>
   <div class="layout-px-spacing" v-if="
        this.$can('admin') ||
        this.$can('office') ||
        this.$can('technik-manager') ||
        this.$can('obchodnik-manager') ||
        this.$can('rola-pobocka') ||
        this.$can('skladnik') ||
        this.$can('kurier') ||
        this.$can('infolinka') ||
        this.$can('technik')
   ">
      <portal to="breadcrumb">
         <ul class="navbar-nav flex-row">
            <li>
               <div class="page-header">
                  <nav class="breadcrumb-one" aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:;">{{ $t('movement') }}</a></li>
                        <li class="breadcrumb-item active" aria-current="page"><span>{{ $t('edit') }}</span></li>
                     </ol>
                  </nav>
               </div>
            </li>
         </ul>
      </portal>

      <div class="row layout-spacing">
         <div id="flFormsGrid" class="col-lg-12 layout-top-spacing">
            <div class="statbox panel box box-shadow">
               <div class="panel-heading">
                  <div class="row">
                     <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                        <h4>{{ $t('create_issue_card') }}</h4>
                     </div>
                  </div>
               </div>
               <div class="panel-body">
                  <b-form @submit.prevent="submitForm" ref="form">
                     <div class="row align-items-center mb-md-4">
                        <div class="col-12 mt-0">
                           <b-form-row class="d-none">
                              <b-form-group class="col-md-6" :label="$t('type')">
                                 <b-select readonly :state="validateState('type')" v-model="$v.form.type.$model">
                                    <b-select-option :value="null">Vybrať...</b-select-option>
                                    <b-select-option value="IN" key="IN">
                                       {{ $t('receipt_card') }}
                                    </b-select-option>
                                    <b-select-option value="OUT" key="OUT">
                                       {{ $t('issue_card') }}
                                    </b-select-option>
                                 </b-select>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row class="over-1">
                              <b-form-group class="col-md-6" :label="$t('sender')">
                                 <b-form-input readonly v-model="form.from_user_name" type="text" placeholder=""></b-form-input>
                                 <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                              </b-form-group>


                              <b-form-group class="col-md-6" :label="$t('recipient')">
                                 <multiselect @change="$v.form.to_user_id.$touch()" :can-deselect="false" :allow-empty="false" :showNoResults="false" v-model="form.to_user_id" :state="validateState('to_user_id')" :deselectLabel="$t('clear')" :selectLabel="$t('select')" :selectedLabel="$t('selected')" :tag-placeholder="$t('add_as_new_tag')" :placeholder="$t('search')" label="name" track-by="id" :options="usersWithoutMe" :multiple="false" :closeOnSelect="true"></multiselect>
                                 <span v-if="!$v.form.to_user_id.required" class="invalid-feedback d-block">{{ $t('required_field') }}</span>
                              </b-form-group>
                           </b-form-row>

                           <b-form-row>
                              <b-form-group class="col-md-12" :label="$t('products')">
                                 <div class="form-repeater w-100 custom-table">
                                    <table class="table table-responsive-detail">
                                       <thead>
                                          <tr>
                                             <th style="width: 30%;min-width: 200px">{{ $t('name') }}</th>
                                             <th style="width: 30%;min-width: 200px">{{ $t('count') }}</th>
                                             <th style="width: 30%;min-width: 200px">{{ $t('note') }}</th>
                                             <th style="width: 30%;min-width: 200px">{{ $t('images') }}</th>
                                             <th class="text-center">{{ $t('check') }}</th>
                                             <!--                        <th class="text-center">{{ $t('delete') }}</th>-->
                                          </tr>
                                       </thead>
                                       <tbody class="form-repeater">
                                          <tr v-for="(row, index) in form.rows" :key="index" class="row-unchecked" :class="{ 'row-checked': row.check }">
                                             <td>
                                                <b-form-input disabled v-model="row.name"></b-form-input>
                                             </td>
                                             <td>
                                                <b-input-group class="">
                                                   <template #append>
                                                      <b-input-group-text><strong v-if="row.unit" class="">{{ row.unit.name }}</strong></b-input-group-text>
                                                   </template>
                                                   <b-form-input v-model="row.quantity" type="number" min="0" placeholder=""></b-form-input>
                                                </b-input-group>
                                                <b-form-invalid-feedback>{{ $t('required_field') }}</b-form-invalid-feedback>
                                             </td>
                                             <td>
                                                <b-form-input v-model="row.note" type="text" placeholder=""></b-form-input>
                                             </td>
                                             <td>
                                                <upload-media @media="(data) => save_media(data, row.id)" @updatedMedia="update_media" :mediaFromLocalStorage="row.files" :sectionId="row.id"></upload-media>
                                             </td>

                                             <td class="text-center align-middle">
                                                <b-form-checkbox v-model="row.check" size="lg"></b-form-checkbox>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row>
                              <b-form-group class="col-md-12" :label="$t('note_on_movement')">
                                 <b-form-textarea id="textarea" v-model="$v.form.note.$model" :placeholder="$t('note')"
                                                  rows="4"></b-form-textarea>
                              </b-form-group>
                           </b-form-row>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-12 text-right">
                           <b-button @click="$router.go(-1)" variant="cancel" class="mt-3">{{ $t('cancel') }}</b-button>
                           <b-button type="submit" variant="default" class="mt-3 ml-2">{{ $t('save') }}</b-button>
                        </div>
                     </div>
                  </b-form>

               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="layout-px-spacing d-flex align-items-center justify-content-center" v-else>
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center px-3">{{ $t('not_allowed') }}</h4>
         </div>
      </div>
   </div>
</template>

<script>
   import { validationMixin } from "vuelidate";
   import { required } from "vuelidate/lib/validators";
   import Multiselect from "vue-multiselect";
   import "vue-multiselect/dist/vue-multiselect.min.css";
   import uploadMedia from "@/components/file_uploads/UploadMedia";
   import { urlMixin } from "../../../mixins/url";

   export default {
      name: "edit",
      components: { Multiselect, uploadMedia },
      mixins: [validationMixin, urlMixin],

      validations: {
         form: {
            // name: {},
            type: {
               required
            },
            from_user_id: {
               required
            },
            to_user_id: {
               required
            },
            note: {},
            rows: {
               $each: {
                  id: {
                     required
                  },
                  quantity: {
                     required
                  }
               }
            }
         }
      },
      data() {
         return {
            orderActual: {},
            form: {
               // name: "",
               type: "OUT",
               from_user_id: null,
               from_user_name: null,
               to_user_id: null,
               note: "",
               rows: []
               // rows: [{id: null, quantity: 0, note: ""}],
            }
         };
      },

      created() {
      },
      async mounted() {
         await this.loading();
      },
      computed: {
         users() {
            return this.$store.state.user.users;
         },
         items() {
            const targetName = "note";
            const objectsArray = this.$store.state.item.items;
            for (let i = 0; i < objectsArray.length; i ++) {
               objectsArray[i][targetName] = "";
            }
            return objectsArray;
         },
         usersWithoutMe() {
            return this.users.filter(user => user.id !== this.form.from_user_id);
         }
      },
      watch: {
         orderActual(order) {
            // this.form.name = order.name;
            this.form.type = "OUT";
            // if (order.movements.length === 0) {
            //   this.form.type = 'OUT'
            //   this.form.from_user_id = order.from_user.id;
            // this.form.to_user_id = null;
            // }
            // this.form.from_user_id = order.movements.length > 0 ? order.movements[order.movements.length - 1].to_user.id : order.from_user.id;
            if (this.form.from_user_id === order.created_by) {
               this.form.to_user_id = null;
            } else {
               this.form.to_user_id = this.users.find(option => option.id === order.created_by);
            }

            // this.form.type = order.movements.length > 0 ? (order.movements[order.movements.length - 1].type === 'IN' ? 'OUT' : 'IN') : 'OUT';
            // this.form.note = order.note;
            let t = this;
            this.form.rows = [];
            if (order.movements.length > 0) {
               order.movements[order.movements.length - 1].items.forEach(function(value) {
                  t.form.rows.push({
                     id: value.id,
                     name: value.name,
                     quantity: value.quantity,
                     note: value.note === null ? "" : value.note,
                     unit: value.unit,
                     check: false
                  });
               });
            } else {
               order.items.forEach(function(value) {
                  t.form.rows.push({
                     id: value.id,
                     name: value.name,
                     quantity: value.quantity,
                     note: value.note === null ? "" : value.note,
                     unit: value.unit,
                     check: false
                  });
               });
            }
            this.loadFilesFromLocalStorage();
            // this.form.rows = order.rows;
         }
      },
      methods: {
         async loading() {
            this.$axios.get("user")
              .then(response => {
                 this.form.from_user_id = response.data.pouzivatel.id;
                 this.form.from_user_name = response.data.pouzivatel.name;
              });
            this.$store.dispatch("user/getAllUsers");
            // this.$store.dispatch("auth/getUserData");
            this.$store.dispatch("item/getItems");
            this.$axios.get("orders/" + this.$route.params.order)
              .then((response) => {
                 this.orderActual = response.data;
              });
         },
         addRow() {
            this.form.rows.push({ id: null, quantity: 0, note: "" });
         },
         removeRow(index) {
            this.form.rows.splice(index, 1);
         },
         validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
         },
         loadFilesFromLocalStorage() {
            const savedFiles = localStorage.getItem(`order-${this.orderActual.id}-files`);
            if (savedFiles) {
               const files = JSON.parse(savedFiles);
               this.form.rows.forEach(row => {
                  if (files[row.id]) {
                     row.files = files[row.id];
                  }
               });
            }
         },
         saveFilesToLocalStorage() {
            const files = {};
            this.form.rows.forEach(row => {
               if (row.files && row.files.length > 0) {
                  files[row.id] = row.files;
               }
            });
            localStorage.setItem(`order-${this.orderActual.id}-files`, JSON.stringify(files));
         },
         clearFilesFromLocalStorage() {
            localStorage.removeItem(`order-${this.orderActual.id}-files`);
         },
         update_media(sectionId, media) {
            const savedFiles = localStorage.getItem(`order-${this.orderActual.id}-files`);
            if (savedFiles) {
               const files = JSON.parse(savedFiles);
               if (files[sectionId]) {
                  files[sectionId] = media;
                  localStorage.setItem(`order-${this.orderActual.id}-files`, JSON.stringify(files));
               }
            }
         },
         save_media(data, rowId) {
            const row = this.form.rows.find(r => r.id === rowId);
            if (row) {
               row.files = data;
               this.saveFilesToLocalStorage();
            }
         },
         submitForm() {
            const t = this;
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
               this.$toast.error(t.$t("missing_credentials"));
               return;
            }

            let data = {
               // name: this.form.name,
               from_user_id: this.form.from_user_id,//this.orderActual.from_user.id,
               to_user_id: this.form.to_user_id.id,
               note: this.form.note,
               type: this.form.type
            };

            let formData = new FormData();

            for (let key in data) {
               formData.append(key, data[key]);
            }

            let hasError = false;
            let showToastError = true;

            if (!this.$can("office") && !this.$can("admin") && !this.$can("infolinka")) {
               this.form.rows.forEach(function(value) {
                  if (!value.files || value.files.length === 0) {
                     if (showToastError) {
                        t.$toast.error(t.$t("each_item_must_contain_image"));
                        showToastError = false;
                     }
                     hasError = true;
                  }
               }.bind(this));

               if (hasError) {
                  return;
               }
            }


            this.form.rows.forEach(function(value, index) {
               if (value.check !== false) {
                  formData.append("items[" + index + "][id]", value.id);
                  formData.append("items[" + index + "][quantity]", value.quantity);
                  formData.append("items[" + index + "][note]", value.note);

                  if (value.files) {
                     value.files.forEach(function(file, fileIndex) {
                        formData.append("items[" + index + "][attachments][" + fileIndex + "]", file.name);
                     });
                  }
               } else {
                  if (showToastError) {
                     t.$toast.error(t.$t("not_all_items_in_stock"));
                     showToastError = false;
                  }
                  hasError = true;
               }
            });

            if (hasError) {
               return; // Zastavenie odosielania v prípade chyby
            }

            this.$axios.post("orders/" + this.orderActual.id + "/movements", formData)
              .then((response) => {
                 this.clearFilesFromLocalStorage();
                 this.$store.dispatch("order/getOrder");
                 this.$router.go(- 1);
                 this.$toast.success(response.data.message);
              }).catch(error => {
               this.$toast.error(error.response.data.message);
            });
         }
      }
   };
</script>
<style lang="scss">
   @import '@/assets/sass/global/table.scss';


   .custom-checkbox.b-custom-control-lg .custom-control-label::before, .input-group-lg .custom-checkbox .custom-control-label::before,
   .custom-checkbox.b-custom-control-lg .custom-control-label::after, .input-group-lg .custom-checkbox .custom-control-label::after {
      width: 1.65rem;
      height: 1.65rem;
   }


   .custom-control-input:checked ~ .custom-control-label::before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
      border-color: #34ce57;
      background-color: #34ce57;
   }


   .row-unchecked {
      background-color: rgba(228, 96, 109, 0.09);

      .custom-control-input ~ .custom-control-label::before {
         border-color: #e4606d;
      }
   }


   .row-checked {
      background: #35ce571a;

      .custom-control-input ~ .custom-control-label::before {
         border-color: #34ce57;
      }
   }
</style>
