<template>
   <main>
      <loader></loader>
      <router-view></router-view>
   </main>
</template>
<script>
   import Loader from "../components/loader";

   export default {
      components: { Loader }
   };
</script>

<style scoped lang="scss">

   .overlay-loader {
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(20px);
      width: 100%;
      height: 100%;
   }

</style>
