import axios from "axios";

export default {
    namespaced: true,
    state: {
        itemId: null,
        reservations: [],
        reservations_meta: [],
    },
    mutations: {
        SET_ITEMS(state, reservations) {
            state.reservations = reservations//.data
            // state.reservations_meta = reservations.meta
        },
        SET_ITEM_ID(state, itemId) {
            state.itemId = itemId//.data
            // state.reservations_meta = reservations.meta
        },
    },
    actions: {
        getItemReservations({commit}, payload) {
            let params = {
                'user_id': payload.user_id,
                'item_id': payload.item_id
            };
            axios.get(`reservations`, {params: new URLSearchParams(params)})
                .then(response => {
                    commit('SET_ITEMS', response.data)
                    commit('SET_ITEM_ID', payload.item_id)
                })
        },
    },
}
