<template>
   <div class="layout-px-spacing" v-if="
        this.$can('admin') ||
        this.$can('office') ||
        this.$can('technik-manager') ||
        this.$can('obchodnik-manager') ||
        this.$can('rola-pobocka') ||
        this.$can('skladnik') ||
        this.$can('kurier') ||
        this.$can('infolinka') ||
        this.$can('technik')
   ">
      <breadcrumb />

      <div class="row layout-top-spacing">
         <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
            <div class="panel p-0">
               <div class="custom-table table3">
                  <div class="table-header">
                     <h3>{{ $t('orders') }}</h3>
                     <div class="header-search">
                        <router-link to="/objednavky/new" v-b-tooltip.hover :title="$t('create')">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-plus-circle">
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                           </svg>
                        </router-link>
                        <div class="header-search">
                           <input type="text" v-model.trim="search_value" :placeholder="$t('search')"
                                  class="form-control form-control-sm"
                                  id="__BVID__321">
                           <div class="search-image">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                   class="feather feather-search">
                                 <circle cx="11" cy="11" r="8"></circle>
                                 <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                              </svg>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="table-responsive">
                     <table class="table table-hover">
                        <thead>
                           <tr>
                              <th>{{ $t('identifier') }}</th>
                              <th>{{ $t('name') }}</th>
                              <th>{{ $t('status') }}</th>
                              <th>{{ $t('customer') }}</th>
                              <th>{{ $t('supplier') }}</th>
                              <th>{{ $t('date_of_creation') }}</th>
                              <th class="actions text-center">{{ $t('actions') }}</th>
                           </tr>
                        </thead>
                        <tbody is="transition-group">
                           <tr v-if="orders.length === 0" key="empty">
                              <td colspan="7" class="text-center">{{ $t('no_orders') }}</td>
                           </tr>
                           <tr v-for="order in orders" :key="order.id">
                              <td>
                                 <router-link :to="'/objednavky/' + order.id + '/detail'" class="badge inv-status badge-dark"
                                              v-b-tooltip.hover :title="$t('detail')">{{ order.identifier }}
                                 </router-link>
                              </td>
                              <td>{{ order.name ?? "-" }}</td>
                              <td>
                                 <span class="badge inv-status" :class="getClass(order.status_value)">
                                    {{ getTranslation(order.status_value) }}
                                 </span>
                              </td>
                              <td>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                      class="feather feather-user placeholder">
                                    <path data-v-17470ab0="" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle data-v-17470ab0="" cx="12" cy="7" r="4"></circle>
                                 </svg>
                                 {{ order.from_user.name }}
                              </td>
                              <td>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                      class="feather feather-user placeholder">
                                    <path data-v-17470ab0="" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle data-v-17470ab0="" cx="12" cy="7" r="4"></circle>
                                 </svg>
                                 {{ order.to_user.name }}
                              </td>
                              <td class="time-status">{{ order.created_at }}</td>
                              <td class="action-btn text-center">
                                 <router-link :to="'/objednavky/' + order.id + '/detail'" class="mr-1" v-b-tooltip.hover
                                 :title="$t('detail')">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                         class="bi bi-info-circle"
                                         stroke="currentColor"
                                         stroke-width=".3"
                                         viewBox="0 0 16 16"
                                    >
                                       <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                       <path
                                         d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                    </svg>
                                 </router-link>
                              </td>

                           </tr>
                        </tbody>
                     </table>
                  </div>

                  <div class="table-footer">
                     <!--              <div class="d-flex align-orders-center"><span>Results :</span><span class="ml-2"><select v-model="per_page"
                                                                                                                            class="custom-select custom-select-sm" id="__BVID__320"><option value="5">5</option><option
                                       value="10">10</option><option value="20">20</option><option value="50">50</option></select></span>
                                   </div>-->
                     <div v-if="orders">{{ $t('it_is_displayed') }} {{ orders.length }} {{ $t('of') }} {{ meta.total }}</div>
                     <div class="paginating-container pagination-solid flex-column align-orders-right">
                        <b-pagination
                          v-if="orders"
                          prev-text="Prev"
                          next-text="Next"
                          first-text="First"
                          last-text="Last"
                          first-class="first"
                          prev-class="prev"
                          next-class="next"
                          last-class="last"
                          class="rounded"
                          v-model="meta.current_page"
                          :total-rows="meta.total"
                          :per-page="meta.per_page"
                          @change="handlePageChange"
                        >
                           <template #first-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                       d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #prev-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #next-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                              </svg>
                           </template>
                           <template #last-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                       d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                              </svg>
                           </template>
                        </b-pagination>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>

   </div>
   <div class="layout-px-spacing d-flex align-items-center justify-content-center" v-else>
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center px-3">{{ $t('not_allowed') }}</h4>
         </div>
      </div>
   </div>
</template>

<script>
   import "../../assets/sass/global/table.scss";
   import _debounce from "lodash/debounce";
   import { getStatusClass, getStatusTranslation } from "./statusHelper";

   export default {
      data() {
         return {
            per_page: 100,
            search_value: "",
            page: 1,
            options: [
               { value: "5", text: "5" },
               { value: "10", text: "10" },
               { value: "20", text: "20" },
               { value: "50", text: "50" }
            ]
         };
      },
      watch: {
         search_value: {
            handler: _debounce(function() {
               this.page = 1;
               this.fetchData();
            }, 250),
            immediate: false
         },
         per_page() {
            if (this.per_page > this.meta.total) {
               this.page = 1;
            }
            this.fetchData();
         }
      },
      created() {
         this.fetchData();
      },
      methods: {
         fetchData() {
            this.$store.dispatch("order/getOrders", {
               page: this.page,
               per_page: this.per_page,
               search_value: this.search_value
            });
         },
         handlePageChange(value) {
            this.page = value;
            this.fetchData();
         },
         delete_order(order) {
            if (window.confirm(this.$t("really_want_to_remove") + " " + order + "?\n\n" + this.$t("not_change_state_order"))) {
               this.$axios.delete(`orders/${order}`)
                 .then((response) => {
                    this.$store.dispatch("order/getOrders");
                    this.$toast.success(response.data.message);
                 })
                 .catch(error => {
                    // console.log(error)
                    this.$toast.error(error.data.error);
                 });
            }
         },
         getClass(statusValue) {
            return getStatusClass(statusValue);
         },
         getTranslation(statusValue) {
            return this.$t(getStatusTranslation(statusValue));
         }
      },
      computed: {
         orders() {
            return this.$store.state.order.orders;
         }, meta() {
            return this.$store.state.order.orders_meta;
         }
      }
   };
</script>

<style scoped>
   .table {
      white-space: nowrap;
   }

   .active-status {
      content: "";
      position: absolute;
      bottom: 7%;
      right: -4%;
      width: 30%;
      height: 30%;
      border-radius: 50%;
      border: 2px solid #fff;
      background-color: #1abc9c;
   }

   .usr-img-frame {
      position: relative;
   }

   .table-header h3 {
      margin-bottom: 0.6rem;

      @media (max-width: 576px) {
         margin-bottom: 1.2rem;
      }
   }
</style>
