import axios from "axios";
import store from "./index";
// import router from "../router";

export default {
  namespaced: true,

  state: {
    userData: null
  },

  getters: {
    user: state => state.userData
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user;
    }
  },

  actions: {
    getUserData({ commit }) {
      axios
        .get("user")
        .then(response => {
          commit("setUserData", response.data);
        })
        // .catch(() => {
        //   localStorage.removeItem("authToken");
        // });
    },
    sendLoginRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return new Promise(() => {
        axios.post("login", data)
            .then(response => {
              commit("setUserData", response.data.user);
              localStorage.setItem("authToken", response.data.token);
              // router.push({ name: "protokoly" });
                window.location.reload()
              this._vm.$toast.info(response.data.message);
              console.clear()
            }).catch(() => {
              store.dispatch('loader/done');
        })

      })

    },
    sendRegisterRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post("register", data)
        .then(response => {
          commit("setUserData", response.data.user);
          localStorage.setItem("authToken", response.data.token);
        });
    },
    sendLogoutRequest({ commit }) {
      return new Promise(() => {
        axios.post("logout")
            .then((response) => {
              commit("setUserData", null);
              localStorage.removeItem("authToken");
              // router.push({ name: "Login" });
                window.location.reload()
              this._vm.$toast.info(response.data.message);
            }).catch(() => {
            store.dispatch('loader/done');
        })

      })
    },
  }
};
