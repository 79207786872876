import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
// import Permissions from "../mixins/Permissions";

import Login from '../views/auth/login.vue';
import Pouzivatelia from '../views/pouzivatelia';
import NewPouzivatel from '../views/pouzivatelia/new.vue';
import EditPouzivatel from '../views/pouzivatelia/_pouzivatel/edit.vue';
import Polozky from '../views/polozky';
import PolozkyPouzivatelov from '../views/polozky-pouzivatelov';
import Objednavky from '../views/objednavky';
import ObjednavkaNew from '../views/objednavky/new.vue';
import ObjednavkaEdit from '../views/objednavky/_objednavka/edit.vue';
import ObjednavkaDetail from '../views/objednavky/_objednavka/index.vue';
import AddReceiveMovement from '../views/objednavky/_objednavka/add_receive_movement.vue';
import AddSendMovement from '../views/objednavky/_objednavka/add_send_movement.vue';
import NewReservation from '../views/polozky-pouzivatelov/new-reservation.vue';
import NewSamPrijemka from '../views/sklad/new-sam-prijemka.vue';
import NewSamVydajka from '../views/sklad/new-sam-vydajka.vue';
import Error404 from '../views/errors/error404.vue';
import Error500 from '../views/errors/error500.vue';
import Navody from '../views/navody/index.vue';
import Sklad from '../views/sklad';
import Historia from '../views/historia';

Vue.use(VueRouter);


const guest = (to, from, next) => {
   if (!localStorage.getItem("authToken")) {
      store.dispatch("loader/done");
      return next();
   } else {
      return next("/objednavky");
   }
};
const auth = (to, from, next) => {
   if (localStorage.getItem("authToken")) {
      return next();
   } else {
      store.dispatch("loader/done");
      return next("/login");
   }
};

const routes = [
   //dashboard
   {
      path: "/",
      name: "Home",
      beforeEnter: auth,
      redirect: "/objednavky"
   },

   {
      path: "/login",
      name: "Login",
      meta: { layout: "auth" },
      beforeEnter: guest,
      component: Login
   },


   //POUŽÍVATELIA

   {
      path: "/pouzivatelia",
      name: "pouzivatelia",
      // meta: {permissions: 'admin'},
      meta: "Používatelia",
      beforeEnter: auth,
      component: Pouzivatelia
   },

   {
      path: "/pouzivatelia/new",
      name: "new_pouzivatel",
      meta: "Vytvoriť nového používateľa",
      beforeEnter: auth,
      component: NewPouzivatel
   },

   {
      path: "/pouzivatelia/:pouzivatel/edit",
      name: "edit_pouzivatel",
      meta: "Edit používateľa",
      beforeEnter: auth,
      component: EditPouzivatel
   },

   //POLOZKY
   {
      path: "/polozky",
      name: "polozky",
      // meta: {permissions: 'admin'},
      meta: "Položky",
      beforeEnter: auth,
      component: Polozky
   },

   {
      path: "/polozky-pouzivatelov",
      name: "polozky-pouzivatelov",
      // meta: {permissions: 'admin'},
      meta: "Položky používateľov",
      beforeEnter: auth,
      component: PolozkyPouzivatelov
   },
   {
      path: "/rezervacie/new",
      name: "nova-rezervacia",
      // meta: {permissions: 'admin'},
      meta: "Nová rezervácia",
      beforeEnter: auth,
      component: NewReservation
   },

   {
      path: "/historia",
      name: "historia",
      // meta: {permissions: 'admin'},
      meta: "Historia",
      beforeEnter: auth,
      component: Historia
   },

   //SKLADY
   {
      path: "/sklad",
      name: "sklad",
      // meta: {permissions: 'admin'},
      meta: "Sklad",
      beforeEnter: auth,
      component: Sklad
   },

   {
      path: "/sklad/sam-prijemka/new",
      name: "new_self_receive_movement",
      // meta: {permissions: 'admin'},
      meta: "Nová samostatná príjemka",
      beforeEnter: auth,
      component: NewSamPrijemka
   },

   {
      path: "/sklad/sam-vydajka/new",
      name: "new_self_send_movement",
      // meta: {permissions: 'admin'},
      meta: "Nová samostatná výdajka",
      beforeEnter: auth,
      component: NewSamVydajka
   },


   //OBJEDNÁVKY

   {
      path: "/objednavky",
      name: "objednavky",
      //meta: {permissions: ['admin']},
      meta: "Objednávky",
      beforeEnter: auth,
      component: Objednavky
   },

   {
      path: "/objednavky/new",
      name: "new_objednavka",
      meta: "Vytvoriť novú objednávku",
      beforeEnter: auth,
      component: ObjednavkaNew
   },

   {
      path: "/objednavky/:order/edit",
      name: "edit_objednavka",
      meta: "Edit objednávky",
      beforeEnter: auth,
      component: ObjednavkaEdit
   },
   {
      path: "/objednavky/:order/detail",
      name: "detail_objednavka",
      meta: "Detail objednávky",
      beforeEnter: auth,
      component: ObjednavkaDetail
   },
   {
      path: "/objednavky/:order/nova_prijemka",
      name: "nova_prijemka_objednavky",
      meta: "Vytvoriť príjemku",
      beforeEnter: auth,
      component: AddReceiveMovement
   },

   {
      path: "/objednavky/:order/nova_vydajka",
      name: "nova_vydajka_objednavky",
      meta: "Vytvoriť výdajku",
      beforeEnter: auth,
      component: AddSendMovement
   },

   //NÁVODY

   {
      path: "/navody",
      name: "navody",
      meta: "Návody",
      beforeEnter: auth,
      component: Navody
   },

   //404
   {
      path: "/404",
      name: "404",
      beforeEnter: auth,
      component: Error404,
      meta: { layout: "auth" }
   },
   {
      path: "/500",
      name: "500",
      beforeEnter: auth,
      component: Error500,
      meta: { layout: "auth" }
   },
   {
      path: "*",
      redirect: "/404"
   }


];

const router = new VueRouter({
   mode: "history",
   linkExactActiveClass: "active",
   routes,
   scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
         return { savedPosition, behavior: "smooth" };
      } else {
         return { x: 0, y: 0, behavior: "smooth" };
      }
   }
});


router.beforeEach((to, from, next) => {
   // console.log(to.meta);
   // if (to.meta && to.meta.permissions && to.meta.permissions instanceof Array
   //     && !to.meta.permissions.filter(permission => Permissions.can(permission))) {
   //     next({name: '404'});
   // } else {
   if (to.meta && to.meta.layout && to.meta.layout === "auth") {
      store.commit("setLayout", "auth");
   } else {
      store.commit("setLayout", "app");
   }
   next(true);
   // }
});

export default router;
